import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CardExersiceContext } from "./../../Contexts/CardsExesciseContext";

import Swal from "sweetalert2";

import CardExercise from "../CardExercise/CardExersice";
import { Grid } from "react-loader-spinner";

const FormBox = ({
  data,
  isShowTreemenu,
  isCustomizedTab,
  isFromLibrary,
  onRemoveExercise,
}) => {
  const navigate = useNavigate();
  // const [isTokenLocalStorage, setIsTokenLocalStorage] = useState(false);
  const { isShowAlertError, setIsShowAlertError } =
    useContext(CardExersiceContext);

  return (
    data?.length > 0 &&
    data.map((card) => (
      <div
        key={card.keyID}
        className="card-container"
        style={{
          flex: "4 0 calc(25% - 16px)",
          margin: "8px",
        }}
      >
        <CardExercise
          onRemoveExercise={onRemoveExercise}
          isCustomizedTab={isCustomizedTab}
          isFromLibrary={isFromLibrary}
          {...card}
          isShowTreemenu={isShowTreemenu}
        />
      </div>
    ))
  );
};

export default FormBox;
