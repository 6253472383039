import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Add } from "@mui/icons-material";

import MuiRTL from "../MuiRTL/MuiRTL";
import Treemenu from "../Treemenu/Treemenu";
import SearchBar from "../SearchBar/SearchBar";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import MyList from "../ListItemsEducationPage/ListItemsEducationPage";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

// Context
import { CardExersiceContext } from "./../../Contexts/CardsExesciseContext";
import "./ModalWithMenuForEducationIcon_L_S.css";
import { PackageID } from "../../Contexts/PackageID";
import { HOST_NAME } from "../../config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalWithMenu() {
  const [open, setOpen] = useState(false);
  const [modalListContent, setModalListContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countPage, setCountPage] = useState(1);
  const [currentPage, setCurrentPage] = useState("");
  const [treeMenuDataEducation, setTreeMenuDataEducation] = useState("");
  const { keyIdItemsEducation, setKeyIdItemsEducation } =
    useContext(CardExersiceContext);
  const { packageID } = useContext(PackageID);

  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAllDataTreeMenu();
  }, []);

  const getAllDataTreeMenu = () => {
    const localStorageData = localStorage.getItem("user");

    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/EducationCtg`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const treeMenuDataWithShowAll = [
            {
              keyID: -1,
              ctgName: "نمایش همه",
              fK_ParentID: null,
            },
            ...data,
          ];

          setTreeMenuDataEducation(treeMenuDataWithShowAll);
        })
        .catch((e) => console.log(e));
    }
  };

  const getEducationList = (key, pageNumber, package_ID) => {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      const pageSize = 8;
      setLoading(false);

      setIsLoading(true);

      fetch(
        `${HOST_NAME}/api/v1/Education/${key},${pageNumber},${pageSize},${package_ID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoading(true);
          setIsLoading(false);
          setModalListContent(data.educationlist);
          setCountPage(Math.ceil(data.totalcount / pageSize));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (keyIdItemsEducation) {
      if (packageID) {
        if ((!currentPage, packageID)) {
          getEducationList(keyIdItemsEducation, 1, packageID);
        } else {
          getEducationList(keyIdItemsEducation, currentPage, packageID);
        }
      } else {
        if ((!currentPage, packageID)) {
          getEducationList(keyIdItemsEducation, 1, 0);
        } else {
          getEducationList(keyIdItemsEducation, currentPage, 0);
        }
      }
    }
  }, [currentPage, keyIdItemsEducation, packageID]);

  const handlePageChangeModal = (event, page) => {
    setCurrentPage(page);
  };

  function handleSearch() {}

  return (
    <div>
      <MuiRTL>
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          style={{ border: "none" }}
        >
          <Add />
          <p style={{ fontSize: "1.2rem" }}>آموزش</p>
        </Button>
        <Dialog
          fullWidth="xl"
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon fontSize="large" />
              </IconButton>
              <Button autoFocus onClick={handleClose}>
                ذخیره
              </Button>
            </Toolbar>
          </AppBar>
          <List>
            <div className="modalbox-education">
              <div className="container" style={{ padding: "10px" }}>
                <div className="row">
                  <div className="treeMenu-modalEducation">
                    <Treemenu data={treeMenuDataEducation} />
                  </div>
                  <div className="content-modalEducation">
                    <SearchBar handleSearch={handleSearch} />
                    <div style={{ padding: "3%" }}>
                      {isLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            margin: "1rem",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <>
                          <div>
                            <MyList
                              getEducationList={getEducationList}
                              dataEducation={modalListContent}
                              isModalEducation={true}
                            />
                          </div>
                          <div>
                            <MuiRTL>
                              <Stack
                                spacing={2}
                                direction="rtl"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "20px",
                                }}
                              >
                                <Pagination
                                  count={countPage}
                                  color="primary"
                                  onChange={handlePageChangeModal}
                                />
                              </Stack>
                            </MuiRTL>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </List>
        </Dialog>
      </MuiRTL>
    </div>
  );
}
