import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { FormatExecutionString } from "../../utils";

export default function TextEditor({
  subject1,
  subject2,
  preparation,
  execution,
  otherDescription,
  detailCardExerciseData,
  reactQuillProps,
  switchPersonalization,
}) {
  const [textEditor, setTextEditor] = useState("");

  // <span style="font-size: 1.5rem ; color: #1976d2">توضیحات تکمیلی</span>
  //           <ul>
  //             ${FormatExecutionString(otherDescription)}
  //           </ul>
  useEffect(() => {
    [subject1, subject2, preparation, execution, otherDescription].every(
      (item) => item !== undefined
    )
      ? setTextEditor(`
          <div style="text-align: right;">
            <span style="font-size: 1.5rem; color: #1976d2">${subject1}</span>
            <ul>
              ${FormatExecutionString(preparation)}
            </ul>
            <span style="font-size: 1.5rem ; color: #1976d2">${subject2}</span>
            <ul>
              ${FormatExecutionString(execution)}
            </ul>
          </div>
        `)
      : setTextEditor("");
  }, [detailCardExerciseData]);

  return (
    <div className="section-detail-caption-cardExersice">
      <div className="list-caption">
        {switchPersonalization ? (
          <ReactQuill
            {...reactQuillProps}
            value={textEditor}
            theme="snow"
            style={{ direction: "ltr", textAlign: "left" }}
          />
        ) : (
          <div
            className="list-caption"
            dangerouslySetInnerHTML={{ __html: textEditor }}
          />
        )}
      </div>
    </div>
  );
}
