import React, { useState, useContext, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import { Link, NavLink, useNavigate } from "react-router-dom";
import SearchBar from "../SearchBar/SearchBar";
import MuiRTL from "../MuiRTL/MuiRTL";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { BiSolidMessageRoundedError, BiSolidPhoneCall } from "react-icons/bi";
import { RiQuillPenFill, RiLogoutCircleLine } from "react-icons/ri";
import { HiTemplate } from "react-icons/hi";
import { FaAppleAlt, FaHome } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import SettingsButton from "../SettingsIconNavbar";
import "./ResponsiveNavbar.css";

import {
  isAuthenticated,
  isUserAdmin,
  isUserRoleActive,
  isUserRoleNewUser,
  parseJwt,
} from "../../utils";
import { PackagesMadeContext } from "../../Contexts/PackagesMade";
import Swal from "sweetalert2";
import { CardExersiceContext } from "../../Contexts/CardsExesciseContext";

import LocalMallIcon from "@mui/icons-material/LocalMall";
import { SubscriptionContext } from "../../Contexts/Subscriptions";
import { HOST_NAME } from "../../config";

const ResponsiveNavbar = () => {
  const [username, setUsername] = useState("");
  const { setIsTabPanelOpen } = useContext(PackagesMadeContext);
  const { setKeyIdItemsCardExersice, setKeyIdItemsEducation } =
    useContext(CardExersiceContext);

  const {
    choosedSubscriptions,
    setChoosedSubscriptions,
    setHasActiveOrder,
    hasActiveOrder,
  } = useContext(SubscriptionContext);

  const navigate = useNavigate();
  const [userValidationState, setUserValidationState] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const baseLinks = [
    {
      label: "خانه",
      to: "/",
      icon: <SettingsButton icon={<FaHome style={{ color: "lightblue" }} />} />,
    },
    {
      label: "درباره ما",
      to: "/about",
      icon: (
        <SettingsButton
          icon={<BiSolidMessageRoundedError style={{ color: "lightblue" }} />}
        />
      ),
    },
    {
      label: "تماس با ما",
      to: "/contact",
      icon: (
        <SettingsButton
          icon={<BiSolidPhoneCall style={{ color: "lightblue" }} />}
        />
      ),
    },
  ];

  let menuItems = [];
  console.log(isUserAdmin(), isAuthenticated(), "header");

  if (isAuthenticated() && isUserAdmin()) {
    menuItems = [
      {
        label: "خانه",
        to: "/",
        icon: (
          <SettingsButton icon={<FaHome style={{ color: "lightblue" }} />} />
        ),
      },
      {
        label: "ساخت برنامه",
        to: "/build",
        icon: (
          <SettingsButton
            icon={<RiQuillPenFill style={{ color: "lightblue" }} />}
          />
        ),
      },
      {
        label: "قالب های آماده",
        to: "/templates",
        icon: (
          <SettingsButton
            icon={<HiTemplate style={{ color: "lightblue" }} />}
          />
        ),
      },
      {
        label: "آموزش ها",
        to: "/education",
        icon: (
          <SettingsButton
            icon={<FaAppleAlt style={{ color: "lightblue" }} />}
          />
        ),
      },
      {
        label: "بیماران",
        to: "/clients",
        icon: (
          <SettingsButton
            icon={<BsFillPeopleFill style={{ color: "lightblue" }} />}
          />
        ),
      },
      {
        label: "مدیریت پیام‌ها",
        to: "/ticketing",
        icon: (
          <SettingsButton
            icon={<ConfirmationNumberIcon style={{ color: "lightblue" }} />}
          />
        ),
      },
      {
        label: "درباره ما",
        to: "/about",
        icon: (
          <SettingsButton
            icon={<BiSolidMessageRoundedError style={{ color: "lightblue" }} />}
          />
        ),
      },
      {
        label: "تماس با ما",
        to: "/contact",
        icon: (
          <SettingsButton
            icon={<BiSolidPhoneCall style={{ color: "lightblue" }} />}
          />
        ),
      },
    ];
  } else {
    menuItems = baseLinks;
  }

  useEffect(() => {
    console.log("hasActiveOrder", hasActiveOrder);
  }, [hasActiveOrder]);

  useEffect(() => {
    const localStorageData = localStorage.getItem("user");

    if (isAuthenticated() && isUserAdmin()) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/Physician/GetLoginedInfoByuserID`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("user data", data);
          console.log("data keyID", data.keyID);
          setHasActiveOrder(data.hasActiveOrder);
          fetch(`${HOST_NAME}/api/v1/Physician/${data.keyID}/PhysicianFiles`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => res.json())
            .then((data) => {
              setUserValidationState(data.result);
            });
        });
    }
    if (
      window.location.pathname !== "/profile" &&
      isAuthenticated() &&
      isUserRoleNewUser()
    ) {
      navigate("/profile");
    }
  }, []);

  function handleLogOut() {
    Swal.fire({
      title: "آیا مطمئن هستید؟",
      text: "آیا می‌خواهید از برنامه خارج شوید؟",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user");
        navigate("/login");
        setIsTabPanelOpen(false);
        setKeyIdItemsCardExersice([]);
        setKeyIdItemsEducation([]);
        setChoosedSubscriptions([]);
      }
    });
  }

  return (
    <div>
      <AppBar position="fixed" style={{ height: "60px" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open menu"
            edge="start"
            onClick={toggleMenu}
            className="menu-icon"
          >
            <MenuIcon />
          </IconButton>
          <div className="main-header__right">
            <ul className="main-header__menu">
              {menuItems.map((item, index) => (
                <li key={index} className="main-header__item">
                  <NavLink to={item.to} className="main-header__link">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        className="link-text"
                        style={{ marginInline: "0.3rem", color: "lightblue" }}
                      >
                        {item.icon}
                      </span>
                      <span
                        className="link-text"
                        style={{ fontSize: "1.5rem" }}
                      >
                        {item.label}
                      </span>
                    </div>
                  </NavLink>
                </li>
              ))}
              <MuiRTL>
                <li dir="rtl" className="main-header__item">
                  {/* <SearchBar />*/}
                </li>
              </MuiRTL>
            </ul>
          </div>

          {/* <img className="header-logo" src="/images/bettermove.png" alt="logo" /> */}
          <div className="main-header__left">
            <ul className="main-header__menu">
              {isAuthenticated() && !isUserRoleNewUser() && (
                <NavLink to="/basket" className="basket-btn">
                  <LocalMallIcon
                    style={{
                      color: "#fff",
                      marginLeft: "1rem",
                      marginTop: "1rem",
                      fontSize: "2rem",
                    }}
                  />
                  {choosedSubscriptions.length > 0 && (
                    <span className="count">{choosedSubscriptions.length}</span>
                  )}
                </NavLink>
              )}

              {isAuthenticated() && isUserRoleNewUser() && (
                <Link to="/profile" className="profile-btn">
                  <AccountCircleIcon />
                  {userValidationState.length ? (
                    <span className="validation-true">در انتظار تایید</span>
                  ) : (
                    <span className="validation-false">!</span>
                  )}
                </Link>
              )}

              {isAuthenticated() ? (
                <>
                  <li
                    className="main-header__item login-register-btns"
                    onClick={handleLogOut}
                  >
                    <RiLogoutCircleLine
                      style={{ color: "#fff", marginLeft: ".2rem" }}
                    />
                    <NavLink to="#" className="main-header__link">
                      <span className="link-text">خروج</span>
                    </NavLink>
                  </li>
                </>
              ) : (
                <div className="login-register-btns">
                  <li className="main-header__item">
                    <NavLink className="main-header__link" to="/register">
                      ثبت نام
                    </NavLink>
                  </li>
                  <span>/</span>
                  <li className="main-header__item">
                    <NavLink className="main-header__link" to="/login">
                      ورود
                    </NavLink>
                  </li>
                </div>
              )}

              <li>
                <img src="/images/bettermove.png" alt="logo" />
              </li>
            </ul>
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Extra Toolbar for spacing */}
      <Drawer anchor="right" open={isMenuOpen} onClose={toggleMenu}>
        <List>
          {menuItems.map((item, index) => (
            <div key={index} style={{ display: "inline-block" }}>
              <ListItem
                button
                component={NavLink}
                to={item.to}
                onClick={toggleMenu}
                style={{
                  fontFamily: "IRANSans",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <NavLink to={item.to} className="mobile-main-header__link">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      className="link-text"
                      style={{ marginInline: "0.3rem", color: "lightblue" }}
                    >
                      {item.icon}
                    </span>
                    <span className="link-text" style={{ fontSize: "1.5rem" }}>
                      {item.label}
                    </span>
                  </div>
                </NavLink>
              </ListItem>
            </div>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default ResponsiveNavbar;
