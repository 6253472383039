import Index from "./pages/Index/Index";
import Templates from "./pages/Templates/Templates";
import Education from "./pages/Education/Education";
import Clients from "./pages/Clients/Clients";
import SignInSide from "./pages/LoginPage/Login";
import SignUpSide from "./pages/Register/Register";
import LoginPage from "./pages/UserPageLogin/UserPageLogin";
import BoxList from "./pages/PackageListClient/PackageListClient";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import Tickets from "./pages/Tickets/Tickets";
import Subscriptions from "./pages/Subscriptions/Subscriptions";
import Profile from "./pages/Profile/Profile";
import RecoveryPassword from "./pages/RecoveryPassword/RecoveryPassword";
import Basket from "./pages/Basket/Basket";
import PaymentVerify from "./pages/PaymentVerify/PaymentVerify";

const routes = [
  { path: "/", element: <Home /> },
  { path: "/contact", element: <Contact /> },
  { path: "/about", element: <About /> },
  { path: "/login", element: <SignInSide /> },
  { path: "/register", element: <SignUpSide /> },
  { path: "/recovery", element: <RecoveryPassword /> },
  { path: "/basket", element: <Basket /> },
  { path: "/payverify", element: <PaymentVerify /> },
  {
    path: "/subscriptions",
    element: <Subscriptions />,
  },
  {
    path: "/build",
    element: (
      <PrivateRoute>
        <Index />
      </PrivateRoute>
    ),
  },
  {
    path: "/profile",

    element: (
      // <PrivateRoute>
      <Profile />
      // </PrivateRoute>
    ),
  },
  {
    path: "/templates",
    element: (
      <PrivateRoute>
        <Templates />
      </PrivateRoute>
    ),
  },
  {
    path: "/education",
    element: (
      <PrivateRoute>
        <Education />
      </PrivateRoute>
    ),
  },
  // { path: '/outcomes', element: <Index /> },
  {
    path: "/clients",
    element: (
      <PrivateRoute>
        <Clients />
      </PrivateRoute>
    ),
  },
  {
    path: "/ticketing",
    element: (
      <PrivateRoute>
        <Tickets />
      </PrivateRoute>
    ),
  },

  // { path: '/loginPage', element: <LoginPage /> },
  // { path: '//link/200', element: <LoginPage /> },
  { path: "/link/:extractedNumber", element: <LoginPage /> },
  // { path: '/link/200', element: <BoxList /> },
  { path: "/boxList", element: <BoxList /> },
];

export default routes;
