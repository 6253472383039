import React, { useState, useContext, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { IconButton, Radio } from "@mui/material";

import FileUploader from "../FileUploader/FileUploader";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import MuiRTL from "../MuiRTL/MuiRTL";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";

import { EducationSelectedInModalInexPage } from "../../Contexts/EducationSelectedInModalInexPageContext";
import { PackageID } from "../../Contexts/PackageID";

import "./ListItemsEducationPage.css";
// import withReactContent from 'sweetalert2-react-content';

import Stack from "@mui/material/Stack";
// import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import { CardExersiceContext } from "../../Contexts/CardsExesciseContext";
import { SelectionEducationIDContext } from "../../Contexts/SelectionEducationID";
import ListItemEducationIndex from "../LeftSide/ListItemsEducationIndex/ListItemEducationIndex";
import { HOST_NAME } from "../../config";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MyList = ({ getEducationList, dataEducation, isModalEducation }) => {
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    setItemList(dataEducation);
  }, [dataEducation]);

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [editedSubDescription, setEditedSubDescription] = useState("");
  const [file, setFile] = useState(null);
  const [editedImage, setEditedImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);

  const { educationSelected, setEducationSelected } = useContext(
    EducationSelectedInModalInexPage
  );
  const [isSelected, setIsSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [seleEduKeyIds, setSeleEduKeyIds] = useState([]);
  const [isItemConfirmrd, setIsItemConfirmrd] = useState(false);
  const { keyIdItemsEducation, setKeyIdItemsEducation } =
    useContext(CardExersiceContext);
  const { selectedItemIDs, setSelectedItemIDs } = useContext(
    SelectionEducationIDContext
  );
  const [selectedItemsSet, setSelectedItemsSet] = useState(new Set()); // ایجاد مجموعه برای آیتم‌های انتخاب شده

  const { packageID } = useContext(PackageID);

  const handleRowClick = (id, event, item) => {
    event.preventDefault();
    const updatedData = itemList.map((item) =>
      item.id === id ? { ...item, isSelected: !item.isSelected } : item
    );
    setIsSelected(true);
    setItemList(updatedData);

    setSelectedItem(item);
    setEditedTitle(item.title);
    setEditedDescription(item.content);
    setEditedSubDescription(item.subContent);
    setEditedImage(item.mainPicturePath);
    setImagePreview(item.mainPicturePath);
    setOpen(true);
  };

  const handleTitleChange = (event) => {
    setEditedTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setEditedDescription(event.target.value);
  };

  const handleSubDescriptionChange = (event) => {
    setEditedSubDescription(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setEditedImage(URL.createObjectURL(file));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleConfirmClick = (id) => {
    const updatedItem = {
      keyID: id,
      title: editedTitle,
      content: editedDescription,
      subTitle: "string",
      subContent: "string",
      educationDate: "2023-10-28T20:16:23.454Z",
      educationCategoryID: keyIdItemsEducation,
    };

    // FETCH EDIT EDUCATION

    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/Education`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedItem),
      })
        .then((result) => {
          getEducationList();
        })
        .catch((error) => {
          console.error("خطا در ارتباط با سرور: ", error);
        });
    }

    setSeleEduKeyIds([...seleEduKeyIds, updatedItem.keyID]);

    // Update the item in the itemList array
    if (itemList) {
      const updatedItemList = itemList.map((item) =>
        item.keyID === updatedItem.keyID ? updatedItem : item
      );
      // Update the itemList with the edited values
      setItemList(updatedItemList);
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openAlert, setOpenAlert] = React.useState(false);
  const [openAlertForRepeat, setopenAlertForRepeat] = React.useState(false);

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleCloseAlertRepeat = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopenAlertForRepeat(false);
  };

  const [checkedItems, setCheckedItems] = useState([]);

  // fetch set  ID Education for Package

  const setIDEducationForPackage = (data) => {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;
      fetch(
        `${HOST_NAME}/api/v1/Package/${packageID}/Educations`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data.map((item) => item.keyID)),
        }
      )
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.error("خطا در ارتباط با سرور: ", error);
        });
    }
  };

  // const handleCheckboxChange = (item) => {
  //   const updatedCheckedItems = {
  //     ...checkedItems,
  //     [item.keyID]: !checkedItems[item.keyID],
  //   };

  //   setCheckedItems(updatedCheckedItems);

  //   if (updatedCheckedItems[item.keyID]) {
  //     setSelectedItemIDs((prevSelectedItems) => [
  //       ...prevSelectedItems,
  //       item.keyID,
  //     ]);
  //   } else {
  //     setSelectedItemIDs((prevSelectedItems) =>
  //       prevSelectedItems.filter((id) => id !== item.keyID)
  //     );
  //   }
  // };
  const handleCheckboxChange = (item) => {
    if (educationSelected.includes(item)) {
      setopenAlertForRepeat(true);
    } else {
      const updatedCheckedItems = {
        ...checkedItems,
        [item.keyID]: true,
      };

      setCheckedItems(updatedCheckedItems);

      setSelectedItemIDs((prevSelectedItems) => [
        ...prevSelectedItems,
        item.keyID,
      ]);
      setEducationSelected((prevSelectedItems) => [...prevSelectedItems, item]);
    }
  };

  // useEffect(() => {
  //   educationSelected.length && setIDEducationForPackage(educationSelected);
  // }, [educationSelected]);

  return (
    <div>
      <MuiRTL>
        <List style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
          {itemList &&
            itemList.map((item) => (
              <ListItemEducationIndex key={item.keyID} {...item} isForBuilder />
              //   <ListItem key={item.id} button>
              //     <ListItemText primary={item.title} />
              //     {isItemConfirmrd && <HistoryEduIcon />}
              //     <div>
              //       <Checkbox
              //         // checked={item.isSelected} // تغییر این قسمت
              //         checked={checkedItems[item.keyID] || false}
              //         onChange={() => handleCheckboxChange(item)}
              //       />
              //       {!isModalEducation && (
              //         <IconButton
              //           onClick={() => {
              //             // handleDeleteEduCationOfList(item.keyID)
              //           }}
              //         >
              //           <DeleteIcon style={{ color: "rgb(176, 56, 60)" }} />
              //         </IconButton>
              //       )}
              //       {/* <IconButton onClick={() => {
              //   handleDeleteEduCationOfList(item.keyID)
              // }}>
              //   <DeleteIcon style={{ color: 'rgb(176, 56, 60)' }}/>
              // </IconButton> */}
              //       <IconButton
              //         onClick={(e) => {
              //           handleRowClick(item.keyID, e, item);
              //         }}
              //       >
              //         <VisibilityIcon style={{ color: "#1976d2" }} />
              //       </IconButton>
              //     </div>
              //   </ListItem>
            ))}
        </List>

        <Dialog open={open} onClose={handleClose}>
          <DialogContent fullWidth="lg">
            <TextField
              style={{ fontFamily: "IRANSans" }}
              fullWidth
              value={editedTitle}
              onChange={handleTitleChange}
            />
            <TextField
              style={{ fontFamily: "IRANSans" }}
              fullWidth
              multiline
              rows={8}
              value={editedDescription}
              onChange={handleDescriptionChange}
            />
            <div>
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ width: "30%" }}
                />
              )}

              <FileUploader
                setFile={setFile}
                style={{
                  padding: "10px",
                  border: "1px",
                  borderRadius: "5px",
                  backgroundColor: "#f8f8f8",
                }}
              >
                <FileUploadIcon fontSize="large" />
                ارسال فایل
              </FileUploader>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleConfirmClick(selectedItem.keyID);
                handleClick();
              }}
              style={{ backgroundColor: "#1976d2" }}
            >
              تایید
            </Button>
            <Button
              onClick={handleClose}
              style={{ backgroundColor: "#ff8a8a" }}
            >
              بستن
            </Button>
          </DialogActions>
        </Dialog>

        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="success"
              style={{
                fontFamily: "IRANSans",
                fontSize: "1.5rem",
                width: "100%",
              }}
            >
              {selectedItem && selectedItem.title} افزوده شد !
            </Alert>
          </Snackbar>
        </Stack>

        <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            open={openAlertForRepeat}
            onClose={handleCloseAlertRepeat}
            autoHideDuration={3000}
          >
            <Alert
              onClose={handleCloseAlertRepeat}
              severity="warning"
              style={{
                fontFamily: "IRANSans",
                fontSize: "1.5rem",
                width: "100%",
              }}
            >
              این مورد قبلا افزوده شده !
            </Alert>
          </Snackbar>
        </Stack>
      </MuiRTL>
    </div>
  );
};

export default MyList;
