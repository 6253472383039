import React from "react";
import { Grid } from "@mui/material";
import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";

export default function Contact() {
  return (
    <>
      <ResponsiveNavbar />

      <div id="contact" className="contact-us">
        <Grid container className="contact-box">
          <Grid item className="contact-form" xs={12} sm={12} md={6}>
            <h2>با ما در ارتباط باشید</h2>

            <form>
              <div className="form-input">
                <label htmlFor="email">ایمیل شما</label>
                <input type="email" id="email" />
              </div>
              <div className="form-input">
                <label htmlFor="subject">موضوع</label>
                <input type="text" id="subject" />
              </div>

              <div className="form-input">
                <label htmlFor="message">پیغام شما</label>
                <textarea id="message" rows={4} />
              </div>

              <button type="submit">ارسال</button>
            </form>
          </Grid>
          <Grid className="contact-img-container" item xs={12} sm={6} md={6}>
            <img className="contact-img" src="/images/contact-us.jpg" alt="" />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
