import React, { useState, useContext, useEffect } from "react";

// Icons
import { Cancel, Expand, ExpandMore, Add } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Collapse,
  Avatar,
  Tab,
  Box,
  IconButton,
  Tooltip,
  Snackbar,
} from "@mui/material";

// Components
import ListItemEducationIndex from "../LeftSide/ListItemsEducationIndex/ListItemEducationIndex";
import CardExercise from "../CardExercise/CardExersice";
import MuiAlert from "@mui/material/Alert";
import ModalWithMenu from "../ModalWithMenuForEducationIcon_L_S/ModalWithMenuForEducationIcon_L_S";
import MuiRTL from "../MuiRTL/MuiRTL";
import ModalIconSave from "./ModalIconSave/ModalIconSave";
import ModalIconSend from "./ModalIconSend/ModalIconSend";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Contexts
import { EducationSelectedInModalInexPage } from "../../Contexts/EducationSelectedInModalInexPageContext";
import { CardContext } from "../../Contexts/CardContext";
import { CreatedNewExersiceCardContext } from "../../Contexts/CreatedNewExersiceCardContext";
import { PackagesMadeContext } from "../../Contexts/PackagesMade";
import { PackageID } from "../../Contexts/PackageID";

import "./Tabs_AddTabs.css";

import CaptionFormCard2 from "../CaptionFormCard2/CaptionFormCard2";
import PackageContent from "../PackageContent/PackageContent";
import useMakePackage from "../../Hooks/useMakePackage";
import { HOST_NAME } from "../../config";

const MyTabs = () => {
  const { getPackageNme } = useMakePackage();
  const {
    isTabPanelOpen,
    setIsTabPanelOpen,
    setCoverContent,
    setIsEditingTabPanel,
    setExerciseParameters,
  } = useContext(PackagesMadeContext);
  const [isClosedCover, setIsClosedCover] = useState(true);
  const [isClosedDevider, setIsClosedDevider] = useState(true);

  const { selectedAddCard, setselectedAddCard } = useContext(CardContext);
  const { educationSelected, setEducationSelected } = useContext(
    EducationSelectedInModalInexPage
  );

  useEffect(() => {
    if (isTabPanelOpen) {
      getPackageNme({});
    } else {
      setIsClosedCover(true);
      setIsClosedDevider(true);
      setselectedAddCard([]);
      setEducationSelected([]);
      setCoverContent({});
      setExerciseParameters([]);
      setIsEditingTabPanel(false);
    }
  }, [isTabPanelOpen]);

  const customTabPanelProps = {
    getPackageNme,
    isClosedCover,
    isClosedDevider,
    setIsClosedCover,
    setIsClosedDevider,
  };

  return (
    <div className="builder" style={{ maxWidth: "600px", margin: "0 auto" }}>
      <MuiRTL>
        <Tab
          style={{ opacity: 0.9 }}
          icon={
            <>
              <Tooltip
                className="tooltip__AddTab"
                style={{ backgroundColor: isTabPanelOpen ? "#EC0000" : null }}
                onClick={() => {
                  setIsTabPanelOpen((prevState) => !prevState);
                }}
              >
                <IconButton>
                  {isTabPanelOpen ? (
                    <Cancel style={{ color: "#fff" }} />
                  ) : (
                    <Add style={{ color: "#fff" }} />
                  )}
                </IconButton>
                {isTabPanelOpen ? "حذف" : "جدید"}
              </Tooltip>
            </>
          }
          value="+"
        />
        <CustomTabPanel {...customTabPanelProps} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "100%" }} />
        </div>
      </MuiRTL>
    </div>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CustomTabPanel = ({
  getPackageNme,
  isClosedCover,
  isClosedDevider,
  setIsClosedCover,
  setIsClosedDevider,
}) => {
  const {
    packagesMade,
    setPackagesMade,
    coverContent,
    setCoverContent,
    isEditingTabPanel,
    isTabPanelOpen,
    setIsTabPanelOpen,
    exerciseParameters,
    setExerciseParameters,
  } = useContext(PackagesMadeContext);

  const { educationSelected, setEducationSelected } = useContext(
    EducationSelectedInModalInexPage
  );
  const { selectedAddCard, setselectedAddCard } = useContext(CardContext);
  const { createdNewExersiceCard, setCreatedNewExersiceCard } = useContext(
    CreatedNewExersiceCardContext
  );

  useEffect(() => {
    console.log("all parameters set finals", exerciseParameters);
  }, [exerciseParameters]);

  /********************************************* HERE */

  useEffect(() => {
    console.log("packages made", packagesMade);

    const parameters = [];
    const parameters2 = [];

    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      packagesMade.exercises.length &&
        packagesMade.exercises
          .filter(
            (exercise) => exercise.type !== "devider" && !exercise.fK_TemplateID
          )
          .forEach((exercise) => {
            const isExerciseExists = exerciseParameters.some(
              (exerciseParams) => exerciseParams.exerciseID === exercise.keyID
            );

            if (!isExerciseExists) {
              const api = exercise.isCustomized
                ? `Getcustomized/${exercise.keyID}`
                : exercise.keyID;

              fetch(`${HOST_NAME}/api/v1/Exercise/${api}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
                .then((res) => res.json())
                .then((data) => {
                  data.links &&
                    fetch(data.links[2].href, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    })
                      .then((res) => {
                        console.log("get params res", res);
                        return res.json();
                      })
                      .then((params) => {
                        console.log("normal", exercise.keyID, params);
                        setExerciseParameters((prevState) => [
                          ...prevState,
                          { exerciseID: exercise.keyID, params },
                        ]);
                        // parameters2.push({
                        //   exerciseID: exercise.keyID,
                        //   params,
                        // });
                      });
                });

              exercise.isCustomized &&
                fetch(`${HOST_NAME}/api/v1/Exercise/${exercise.keyID}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                  .then((res) => res.json())
                  .then((data) => {
                    fetch(
                      `${HOST_NAME}/api/v1/Exercise/${exercise.keyID}/ExerciseParameters/getCustomized`,
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                      .then((res) => res.json())
                      .then((params) => {
                        console.log("custom", exercise.keyID, params);
                        setExerciseParameters((prevState) => [
                          ...prevState,
                          { exerciseID: exercise.keyID, params },
                        ]);

                        // parameters.push({ exerciseID: exercise.keyID, params });
                      });
                  });
            }
          });

      // setExerciseParameters((prevState) => [
      //   ...prevState,
      //   ...parameters,
      //   ...parameters2,
      // ]);
    }
  }, [packagesMade]);

  useEffect(() => {
    setPackagesMade({
      exercises: [
        ...selectedAddCard.map((card, index) => ({ ...card, status: index })),
      ],
      educations: [...educationSelected],
    });
  }, [selectedAddCard, educationSelected]);

  const { packageID, updatePackageIDVariable } = useContext(PackageID);

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [isOpenModalEducation, setIsOpenModalEducation] = useState(false);

  const [expandedRow, setExpandedRow] = useState(null);

  const handleExpandClick = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const handleDeleteRow = (id, index, fK_TemplateID) => {
    const updatedData = selectedAddCard.filter((card, i) => card.keyID !== id);
    setselectedAddCard(updatedData);
    setExpandedRow(null);

    setExerciseParameters((prevState) => [
      ...prevState.filter((exercise, i) => exercise.exerciseID !== id),
    ]);

    if (fK_TemplateID && isEditingTabPanel) {
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        const token = parsedData.token;
        fetch(`${HOST_NAME}/api/v1/Template/DeleteDevider?deviderKeyid=${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => console.log("delete devider res", res));
      }
    }
  };

  const [deviderContent, setDeviderContent] = useState({});

  function dragEndHandler(result) {
    try {
      const { destination, source, draggableId } = result;

      if (!destination) return;

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      const newSelectedAddCard = [...packagesMade.exercises];

      const newCard = newSelectedAddCard.find(
        (card) => card.keyID == draggableId
      );

      newSelectedAddCard.splice(source.index, 1);
      newSelectedAddCard.splice(destination.index, 0, newCard);

      setPackagesMade({
        educations: [...educationSelected],
        exercises: [...newSelectedAddCard],
      });
    } catch {}
  }

  function addDeviderToSelectedCards() {
    const lastCard = selectedAddCard.reduce(
      (max, item) => (item.keyID > max.keyID ? item : max),
      selectedAddCard[0]
    ) || {
      keyID: 1,
    };

    console.log("last card", lastCard);
    setselectedAddCard((prevState) => [
      ...prevState,
      {
        keyID: lastCard.keyID + 1,
        type: "devider",
        ...deviderContent,
      },
    ]);
  }

  const modalIconSaveProps = {
    coverContent,
    setIsTabPanelOpen,
    isEditingTabPanel,
  };
  return (
    isTabPanelOpen && (
      <Box p={3}>
        <Box display="flex" alignItems="center">
          <div
            className="row"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              className="col-2 stylefor_CustomTabPanel"
              onClick={() => setIsOpenModalEducation((prevState) => !prevState)}
            >
              <ModalWithMenu />
            </div>
            <div className="col-2 stylefor_CustomTabPanel">
              <ModalIconSave {...modalIconSaveProps} />
            </div>
            {!isEditingTabPanel && (
              <>
                <div className="col-2 stylefor_CustomTabPanel">
                  <ModalIconSend
                    {...modalIconSaveProps}
                    getPackageNme={getPackageNme}
                  />
                </div>
                <div
                  className="col-2 stylefor_CustomTabPanel"
                  onClick={() => setIsClosedCover(false)}
                >
                  <IconButton>
                    <Add />
                  </IconButton>

                  <p style={{ fontSize: "1.2rem" }}>کاور</p>
                </div>
              </>
            )}

            <div
              className="col-3 stylefor_CustomTabPanel"
              onClick={() => setIsClosedDevider(false)}
            >
              <IconButton>
                <Add />
              </IconButton>

              <p style={{ fontSize: "1.2rem" }}>تقسیم بندی</p>
            </div>
          </div>

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%", fontFamily: "IRANSans", fontSize: "1.5rem" }}
            >
              کپی شد ، میتونید پیست کنید !
            </Alert>
          </Snackbar>
        </Box>
        <DragDropContext onDragEnd={dragEndHandler}>
          <Box>
            <>
              {(!isClosedCover ||
                isEditingTabPanel ||
                coverContent.contentTitle) && (
                <PackageContent
                  isEditingTabPanel={isEditingTabPanel}
                  setContent={setCoverContent}
                  coverContent={coverContent}
                  onClose={setIsClosedCover}
                  type="cover"
                />
              )}
              <MuiRTL>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <Droppable droppableId={1 + ""}>
                      {(provided) => (
                        <TableBody
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {packagesMade
                            ? packagesMade.exercises?.map((data, index) => (
                                <Draggable
                                  draggableId={data.keyID + ""}
                                  index={index}
                                  key={data.keyID}
                                >
                                  {(provided) => (
                                    <>
                                      <TableRow
                                        className={
                                          data.type === "devider"
                                            ? "devider-card"
                                            : "simple-card"
                                        }
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}
                                      >
                                        <TableCell
                                          {...provided.dragHandleProps}
                                        >
                                          <Expand className="drag-icon" />
                                        </TableCell>
                                        {!data.type && !data.fK_TemplateID ? (
                                          <>
                                            <TableCell>
                                              <Avatar
                                                alt={`Avatar ${data.keyID}`}
                                                src={data.mainPicturePath}
                                              />
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontFamily: "IRANSans",
                                              }}
                                            >
                                              {data.name}
                                            </TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="expand"
                                                onClick={() => {
                                                  handleExpandClick(index);
                                                }}
                                              >
                                                <ExpandMore />
                                              </IconButton>
                                            </TableCell>
                                          </>
                                        ) : (
                                          <TableCell colSpan={3}>
                                            <p className="devider-title">
                                              {data.contentTitle || data.title}
                                            </p>
                                            <p className="devider-description">
                                              {data.contentDescription ||
                                                data.instruction}
                                            </p>
                                          </TableCell>
                                        )}
                                        <TableCell>
                                          <IconButton
                                            aria-label="delete"
                                            onClick={() =>
                                              handleDeleteRow(
                                                data.keyID,
                                                index,
                                                data.fK_TemplateID
                                              )
                                            }
                                          >
                                            <Cancel
                                              style={{
                                                color: "rgba(176,56,60,255)",
                                              }}
                                            />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                          }}
                                          colSpan={6}
                                        >
                                          <Collapse
                                            in={expandedRow === index}
                                            timeout="auto"
                                            unmountOnExit
                                            style={{ padding: "5px" }}
                                          >
                                            <CaptionFormCard2
                                              keyID={data.keyID}
                                              isCustomized={data.isCustomized}
                                            />
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )}
                                </Draggable>
                              ))
                            : null}

                          {provided.placeholder}
                        </TableBody>
                      )}
                    </Droppable>

                    {packagesMade
                      ? packagesMade.educations?.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell colSpan={6}>
                              <ListItemEducationIndex
                                isForTemplate={false}
                                {...data}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </Table>
                </TableContainer>
              </MuiRTL>

              {createdNewExersiceCard.length > 0 && (
                <div className="col-12" style={{ display: "flex" }}>
                  {createdNewExersiceCard.map((card) => (
                    <CardExercise
                      className="col-3"
                      subject={card.title}
                      img={card.file.name}
                    />
                  ))}
                </div>
              )}

              {!isClosedDevider && (
                <PackageContent
                  setContent={setDeviderContent}
                  onClose={setIsClosedDevider}
                  onDevider={addDeviderToSelectedCards}
                  type="devider"
                  isEditingTabPanel={isEditingTabPanel}
                  coverContent={deviderContent}
                />
              )}
            </>
          </Box>
        </DragDropContext>
      </Box>
    )
  );
};

export default MyTabs;
