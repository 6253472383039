import React, { createContext, useState } from "react";

export const PackageID = createContext();

export const PackageIDProvider = ({ children }) => {
  const storedPackageValue = localStorage.getItem("storedPackageValue");
  const [packageID, setPackageID] = useState(storedPackageValue || null);

  const [isSectionAdd, setIsSectionAdd] = useState(true);
  const [templateID, setTemplateID] = useState(-1);

  const updatePackageIDVariable = (newValue) => {
    localStorage.setItem("storedPackageValue", newValue);
    setPackageID(newValue);
  };

  return (
    <PackageID.Provider
      value={{
        packageID,
        setPackageID,
        isSectionAdd,
        setIsSectionAdd,
        updatePackageIDVariable,
        templateID,
        setTemplateID,
      }}
    >
      {children}
    </PackageID.Provider>
  );
};
