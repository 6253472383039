import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";

import App from "./App";

import "./styles/fonts.css";
import "./styles/variables.css";
import "./styles/helpers.css";
import "./styles/defaults.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const version = Math.floor(Math.random() * 1000000);
var myHeaders = new Headers();
myHeaders.append("Authorization", "Bearer <MY_API_KEY>");
myHeaders.append(
  "Cookie",
  "__cfduid=db290300ecfe95ec1fe3bc92c388c3c991586618117"
);
myHeaders.append("Access-Control-Allow-Origin", "*");

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
document.getElementById("style-link").href = `/static/css/main.${version}.css`;
document.getElementById("script-tag").src = `/static/js/main.${version}.js`;
