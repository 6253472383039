import React, { createContext, useState } from 'react';

export const UserLoginCodeContext = createContext();

const UserLoginCodeProvider = ({ children }) => {
    const [userLoginCode, setUserLoginCode] = useState([]);

  return (
    <UserLoginCodeContext.Provider value={{ userLoginCode, setUserLoginCode }}>
      {children}
    </UserLoginCodeContext.Provider>
  );
};

export { UserLoginCodeProvider };