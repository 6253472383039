import { styled, Switch } from "@mui/material";
import moment from "jalali-moment";
import * as yup from "yup";
import { HOST_NAME } from "./config";

export function FormatExecutionString(inputstr) {
  if (typeof inputstr !== "string") {
    console.error("Invalid input. Please provide a valid string.");
    return "";
  }
  const steps = inputstr.split("\n");

  const formattedSteps = steps.map(
    (step) => step && `<li style=" list-style: inside;">${step.trim()}</li>`
  );

  const result = formattedSteps.join("\n");
  return result;
}

export function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error("Invalid token", e);
    return null;
  }
}

export function isUserAdmin() {
  const localStorageData = localStorage.getItem("user");
  if (!localStorageData) return false;

  const parsedData = JSON.parse(localStorageData);
  const token = parsedData.token;
  if (!token) return false;

  const decodedToken = parseJwt(token);

  if (!decodedToken) return false;

  const userRole =
    decodedToken[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];

  if (userRole !== "Admin") {
    return false;
  }

  return true;
}

export function isUserRoleNewUser() {
  const localStorageData = localStorage.getItem("user");
  if (!localStorageData) return false;

  const parsedData = JSON.parse(localStorageData);
  const token = parsedData.token;
  if (!token) return false;

  const decodedToken = parseJwt(token);

  if (!decodedToken) return false;

  const userRole =
    decodedToken[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];
  if (userRole === "newuser") {
    return true;
  }
}

export function isUserRoleActive() {
  const localStorageData = localStorage.getItem("user");
  if (!localStorageData) return false;

  const parsedData = JSON.parse(localStorageData);
  const token = parsedData.token;
  if (!token) return false;

  const decodedToken = parseJwt(token);

  if (!decodedToken) return false;

  const userRole =
    decodedToken[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];

  if (userRole === "deactivephysician") {
    return true;
  }
}

export function isAuthenticated() {
  const localStorageData = localStorage.getItem("user");
  if (!localStorageData) return false;

  const parsedData = JSON.parse(localStorageData);
  const token = parsedData.token;
  if (!token) return false;

  const decodedToken = parseJwt(token);

  if (!decodedToken) return false;

  const currentTime = Date.now() / 1000;
  if (decodedToken.exp < currentTime) {
    localStorage.removeItem("token");
    return false;
  }
  return true;
}

export const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export const postCardForPackage = (id, packageID, isCustomized) => {
  const localStorageData = localStorage.getItem("user");

  if (localStorageData) {
    const parsedData = JSON.parse(localStorageData);
    const token = parsedData.token;

    fetch(`${HOST_NAME}/api/v1/Package/${packageID}/Exercises`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        {
          exerciseID: id,
          status: 0,
          isCustomized,
        },
      ]),
    })
      .then((result) => console.log(result))
      .catch((error) => {
        console.error("Error:", error);
      });
  }
};

export const posetEducationForPackage = (id, packageID) => {
  const localStorageData = localStorage.getItem("user");

  if (localStorageData) {
    const parsedData = JSON.parse(localStorageData);
    const token = parsedData.token;

    fetch(`${HOST_NAME}/api/v1/Package/${packageID}/Educations`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify([id]),
    })
      .then((result) => console.log(result))
      .catch((error) => {
        console.error("Error:", error);
      });
  }
};

export function formatDate(dateString) {
  const jalaliDate = moment(dateString, "YYYY-MM-DDTHH:mm:ss.SSSZ")
    .locale("fa")
    .format("YYYY/MM/DD");

  return jalaliDate;
}

export function formatTime(dateString) {
  const formattedTime = moment(dateString).format("HH:mm");

  return formattedTime;
}

export function getFullDate() {
  const tehranTime = new Date()
    .toLocaleString("sv-SE", {
      timeZone: "Asia/Tehran",
    })
    .replace(" ", "T");

  return tehranTime;
}

export function changeYear(duration) {
  const now = new Date();
  now.setFullYear(now.getFullYear() + duration);

  const tehranDate = now
    .toLocaleString("sv-SE", { timeZone: "Asia/Tehran" })
    .replace(" ", "T");

  return tehranDate;
}

export const schema = (input) => {
  return yup.object().shape({
    userName: yup.string().required("نام کاربری الزامی است"),
    password: yup
      .string()
      .min(6, "رمز عبور باید حداقل 6 کاراکتر داشته باشد")
      .required("رمز عبور الزامی است"),
    confirmPassword: yup
      .string()
      .required("تکرار رمز عبور الزامی است")
      .oneOf(
        [yup.ref("password"), null],
        "تکرار رمزعبور با رمزعبور مطابقت ندارد"
      ),
    phone: yup
      .string()
      .matches(
        /^(0|0098|\+98)9(0[1-5]|[1 3]\d|2[0-2]|98)\d{7}$/,
        "شماره تلفن معتبر نیست."
      )
      .required("شماره تلفن الزامی است"),

    captcha: yup
      .string()
      .matches(input, "کد کپچا را اشتباه وارد کردید")
      .required("کد کپچا الزامی است"),
  });
};
