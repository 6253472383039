import React, { useState, useEffect, useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";

import MuiRTL from "../../Components/MuiRTL/MuiRTL";

import "./PackageListClient.css";
import { UserLoginCodeContext } from "../../Contexts/UserLoginCode";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CaptionFormCardForClient from "./CaptionFromCardForClient";
import { HOST_NAME } from "../../config";

export default function AlignItemsList() {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [item_Data, setItem_Data] = useState([]);
  const [keyIDPackages, setKeyIDPackages] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const [isLodingGetData, setIsLodingGetData] = useState(false);
  const [educationsPatientPackages, setEducationsPatientPackages] = useState(
    []
  );
  const [exercisePatientPackages, setexercisePatientPackages] = useState([]);

  const { userLoginCode, setUserLoginCode } = useContext(UserLoginCodeContext);

  const [expandedRow, setExpandedRow] = useState(null);
  const [patPackageid, setpatPackageid] = useState(null);
  const handleExpandClick = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const handleDeleteRow = (id, index) => {
    // const updatedData = selectedAddCard.filter((_, i) => i !== index);
    // setselectedAddCard(updatedData);
    // deleteCardFrompackage(id);
  };

  const handleListItemClick = (item) => {
    setSelectedItem(item);
    setKeyIDPackages(item.keyID);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getInformationPatientPackages = (paitientID) => {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      setIsLoding(true);
      fetch(
        `${HOST_NAME}/api/v1/Patient/${
          userLoginCode / 100
        }/Packages`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setItem_Data(data);
          setIsLoding(false);
        })
        .catch((e) => {
          setIsLoding(true);
        });
    }
  };

  // useEffect(() => {
  //   getInformationPatientPackages()
  // }, [userLoginCode, item_Data, isLoding])

  useEffect(() => {
    getInformationPatientPackages();
  }, [userLoginCode]);

  const getInformationEducationsPatientPackages = (patientPackageID) => {
    setpatPackageid(patientPackageID);
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      // setIsLoding(true)
      fetch(
        `${HOST_NAME}/api/v1/Package/${patientPackageID}/Educations`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setEducationsPatientPackages(data);
          // setIsLoding(false)
        })
        .catch((e) => {
          // setIsLoding(true)
        });
    }
  };

  const getInformationExercisePatientPackages = (patientPackageID) => {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      setIsLodingGetData(true);
      fetch(
        `${HOST_NAME}/api/v1/Package/${patientPackageID}/Exercises`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setexercisePatientPackages(data);
          // setIsLodingGetData(false)
        })
        .catch((e) => {
          setIsLodingGetData(true);
        });
    }
  };

  // useEffect(() => {
  //   getInformationEducationsPatientPackages(keyIDPackages)
  //   getInformationExercisePatientPackages(keyIDPackages)
  // }, [keyIDPackages])

  useEffect(() => {
    if (item_Data.length > 0 && !isLoding) {
      getInformationEducationsPatientPackages(keyIDPackages);
      getInformationExercisePatientPackages(keyIDPackages);
    }
  }, [item_Data, isLoding, keyIDPackages]); // استفاده از item_Data، isLoding و keyIDPackages به عنوان وابستگی ها

  const [educationsFiles, setEducationsFiles] = useState([]);
  const [exerciseFiles, setExerciseFiles] = useState([]);

  const clickToEduCation = (id) => {
    setEducationsFiles([]);
    setExerciseFiles([]);
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      // setIsLodingGetData(true)
      fetch(`${HOST_NAME}/api/v1/Education/${id}/EducationFiles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setModalOpen(true);
          setEducationsFiles(data.result);
          // setIsLodingGetData(false)
        })
        .catch((e) => {
          // setIsLodingGetData(true)
        });
    }
  };

  const clickToExercise = (id) => {
    setEducationsFiles([]);
    setExerciseFiles([]);
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      // setIsLodingGetData(true)
      fetch(`${HOST_NAME}/api/v1/Exercise/${id}/ExerciseFiles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // setexercisePatientPackages(data);
          setModalOpen(true);
          setExerciseFiles(data);

          // setIsLodingGetData(false)
        })
        .catch((e) => {
          // setIsLodingGetData(true)
        });
    }
  };

  const [modalOpen, setModalOpen] = useState(false);

  // const handleMediaClick = (media) => {
  //   // setCurrentMedia(media);
  //   setModalOpen(true);
  // };

  const handleCloseModal = () => {
    setModalOpen(false);
    // setCurrentMedia([]);
  };

  return (
    <div>
      {item_Data.length === 0 && !isLoding && (
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <Alert
            severity="info"
            style={{
              textAlign: "center",
              marginTop: "1rem",
              fontFamily: "IRANSans",
              fontSize: "1.3rem",
            }}
          >
            پکیج فعالی برای شما وجود ندارد.
          </Alert>
        </div>
      )}

      {item_Data.length > 0 && (
        <div
          style={{
            padding: "20px",
            backgroundImage: "url(./images/human-skeleton.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            backgroundAttachment: "fixed",
          }}
        >
          <List
            style={{
              width: "100%",
              maxWidth: 1000,
              margin: "auto",
              marginTop: "1rem",
              border: "5px solid rgb(25, 118, 210)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              borderRadius: "1%",
              backgroundColor: "#d3e2fd",
              opacity: "0.9",
            }}
          >
            {item_Data.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  button
                  onClick={() => handleListItemClick(item)}
                  style={{ border: "1px solid pink" }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={`Avatar ${index + 1}`}
                      src={`/static/images/avatar/${index + 1}.jpg`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.packageName}
                    style={{ fontWeight: "bold" }}
                  />
                  <ListItemText secondary={item.packageDescription} />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={item.startDate}
                    secondary={item.endDate}
                  />
                </ListItem>

                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))}
          </List>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "transform 0.5s ease-in-out",
              overflowY: "auto", // افزودن اسکرول در صورت نیاز
              // maxHeight: '80vh',  // تعیین حداکثر ارتفاع ممکن برای Modal
              // transform: open ? 'translateY(0)' : 'translateY(-100%)',
            }}
          >
            <div
              style={{
                // background: 'linear-gradient(to bottom right, #4e54c8, #8f94fb)',
                backgroundColor: "rgb(209, 215, 222)",
                border: "1px solid #212529f7",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                borderRadius: "10px",
                padding: "20px",
                outline: "none",
                backdropFilter: "blur(5px)", // بک‌گراند بلوری
                WebkitBackdropFilter: "blur(5px)", // بک‌گراند بلوری برای مرورگرهای وبکیت
                overflowY: "auto", // افزودن اسکرول به باکس Modal
                maxHeight: "100%", // تعیین حداکثر ارتفاع ممکن برای باکس Modal
              }}
            >
              <List>
                {educationsPatientPackages.length
                  ? educationsPatientPackages.map((item, index) => (
                      // <ListItem key={index} button onClick={() => handleListItemClick(item)}>
                      <ListItem
                        key={index}
                        button
                        style={{
                          border: "1px solid #5a7588",
                          backgroundColor: "#b3d5ed",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)",
                          borderRadius: "1%",
                        }}
                      >
                        <div>
                          {item.mainPicturePath && (
                            <div style={{ margin: "20px" }}>
                              <img
                                src={item.mainPicturePath}
                                alt="Image"
                                style={{ width: "50%", height: "50%" }}
                              />
                            </div>
                          )}
                        </div>
                        <div>
                          <ListItemText
                            primary={item.title}
                            secondary={item.content}
                          />
                          <ListItemText
                            primary={item.subTitle}
                            secondary={item.subContent}
                          />
                          <Button
                            onClick={() => clickToEduCation(item.keyID)}
                            style={{
                              backgroundColor: "white",
                              width: "80%",
                              margin: "1rem",
                            }}
                          >
                            {" "}
                            نمایش فایلها
                          </Button>
                        </div>
                        <div></div>
                      </ListItem>
                    ))
                  : null}
              </List>
              {/* <List>
            {exercisePatientPackages.length && exercisePatientPackages.map((item, index) => ( */}

              <List>
                {exercisePatientPackages.length ? (
                  //   exercisePatientPackages.map((item, index) => (
                  //   <ListItem key={index}
                  //   button
                  //   onClick={() => clickToExercise(item.keyID)}
                  //     style={{border: '1px solid #5a7588', backgroundColor: '#b3d5ed', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.9)', borderRadius: '1%'}}>
                  //     <div style={{ display: 'flex', alignItems: 'center' }}>
                  //       {item.mainPicturePath && (
                  //         <div style={{ margin: '20px' }}>
                  //           <img src={item.mainPicturePath} alt="Image" style={{ width: '50%', height: '50%' }} />
                  //         </div>
                  //       )}
                  //       <div>
                  //         <ListItemText primary={item.name} style={{}}/>
                  //         <ListItemText primary={item.subject1} secondary={item.preparation} />
                  //         <ListItemText primary={item.subject2} secondary={item.execution} />
                  //         {item.videos && (
                  //           <div>
                  //             <video width="500%" height="50%" controls>
                  //               <source src={item.videos} type="video/mp4" />
                  //               Your browser does not support the video tag.
                  //             </video>
                  //           </div>
                  //         )}
                  //       </div>
                  //     </div>
                  //   </ListItem>
                  // ))

                  <MuiRTL>
                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table">
                        <TableBody>
                          {exercisePatientPackages
                            ? exercisePatientPackages.map((item, index) => (
                                <React.Fragment key={item.keyID}>
                                  <TableRow>
                                    {/* <TableCell>
                            <Checkbox onClick={() => {
                              // postCardForPackage(item.keyID)
                                console.log(item.keyID, 'cart entekhab shode')  
                            }}/>
                          </TableCell> */}
                                    <TableCell>
                                      {/* <Avatar alt={`Avatar ${row.keyID}`} src="./images/566.jpg" /> */}
                                      <Avatar
                                        alt={`Avatar ${item.keyID}`}
                                        src={item.mainPicturePath}
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{ fontFamily: "IRANSans" }}
                                    >
                                      {item.name}
                                    </TableCell>
                                    {/* <TableCell style={{ fontFamily: 'IRANSans' }}>{row.name}</TableCell> */}
                                    <TableCell>
                                      <IconButton
                                        aria-label="expand"
                                        onClick={() => {
                                          handleExpandClick(index);
                                        }}
                                      >
                                        <ExpandMoreIcon />
                                      </IconButton>
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() =>
                                          handleDeleteRow(item.keyID, index)
                                        }
                                      >
                                        {/* <CancelIcon style={{ color: 'rgba(176,56,60,255)' }}/> */}
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                      }}
                                      colSpan={4}
                                    >
                                      <Collapse
                                        in={expandedRow === index}
                                        timeout="auto"
                                        unmountOnExit
                                        style={{ padding: "5px" }}
                                      >
                                        <CaptionFormCardForClient
                                          keyID={item.keyID}
                                          packageID={patPackageid}
                                        />
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </MuiRTL>
                ) : null}
              </List>

              {/* )) */}

              {/* } */}
              {/* </List> */}
            </div>
          </Modal>
        </div>
      )}

      {/* isOpen={modalOpen} handleClose={} media={currentMedia} */}
      <div>
        <Dialog
          open={modalOpen}
          onClose={handleCloseModal}
          scroll="paper"
          fullWidth
          maxWidth="md"
        >
          {/* <DialogTitle>ویدئوها و تصاویر</DialogTitle> */}
          <DialogContent dividers>
            <Stack spacing={2}>
              {1 &&
                educationsFiles.map((item, index) => (
                  <div key={item.keyID} style={{ margin: "2rem" }}>
                    {/* <video width="100%" controls>
                  {item.videoTitle}
                  <source src={item.fullpath} type="video/mp4" />
                  {item.videoDescription}
                  مرورگر شما ویدئو را پشتیبانی نمی‌کند.
                </video> */}
                    {item.isImage ? (
                      <div style={{ padding: "2rem", border: "1px dashed" }}>
                        <img
                          src={item.filesAddress}
                          alt={item.fileDescription}
                          style={{ width: "100%", height: "500px" }}
                        />
                        {item.fileTitle}
                      </div>
                    ) : (
                      <video width="100%" controls>
                        {/* {item.videoTitle}
                  <source src={item.fullpath} type="video/mp4" />
                  {item.videoDescription}
                  مرورگر شما ویدئو را پشتیبانی نمی‌کند. */}
                      </video>
                    )}
                  </div>
                ))}

              {1 &&
                exerciseFiles.map((item, index) => (
                  <div key={item.keyID} style={{ margin: "2rem" }}>
                    {/* <video width="100%" controls>
                  {item.videoTitle}
                  <source src={item.fullpath} type="video/mp4" />
                  {item.videoDescription}
                  مرورگر شما ویدئو را پشتیبانی نمی‌کند.
                </video> */}
                    {item.isImage ? (
                      <div style={{ padding: "1rem", border: "1px dashed" }}>
                        <img
                          src={item.filesAddress}
                          alt={item.fileDescription}
                          style={{ width: "100%", height: "500px" }}
                        />
                        {item.fileTitle}
                      </div>
                    ) : (
                      <video
                        width="100%"
                        controls
                        onError={(e) => console.error("Video error:", e)}
                      >
                        {item.fileTitle}
                        <source src={item.filesAddress} type="video/mp4" />
                        {item.fileDescription}
                        مرورگر شما ویدئو را پشتیبانی نمی‌کند.
                      </video>
                    )}
                  </div>
                ))}
            </Stack>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose} color="primary">
          بستن
        </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
