import React, { useRef } from "react";
import IconButton from "@mui/material/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const FileUploader = ({ children, setFile }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(event);
    // انجام عملیات مورد نیاز با فایل انتخاب شده (مانند ارسال به سرور)
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        type="file"
        accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <IconButton onClick={handleUploadButtonClick}>{children}</IconButton>
    </div>
  );
};

export default FileUploader;
