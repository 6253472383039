import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import AuthContext from "../../Contexts/authContext";
import { UserLoginCodeContext } from "../../Contexts/UserLoginCode";

import { useParams } from "react-router-dom";

import Swal from "sweetalert2";

import "./UserPageLogin";
import { HOST_NAME } from "../../config";

const defaultTheme = createTheme();

const styles = {
  blurredBackground: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  videoBackground: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
};

export default function SignInSide() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { userLoginCode, setUserLoginCode } = useContext(UserLoginCodeContext);
  const { extractedNumber } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [isGoogleRecaotchVerify, setIsGoogleRecaotchVerify] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // const [extractedNumber, setExtractedNumber] = useState('');

  useEffect(() => {
    const currentURL = window.location.href;
    const linkParts = currentURL.split("/");
    const number = linkParts[linkParts.length - 1];

    // setExtractedNumber(number);
    setUserLoginCode(number);
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // fetch lohin api & save in browser
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const userData = {
      userName: userLoginCode, // استفاده از extractedNumber به جای نام کاربری
      password: data.get("password"),
    };

    fetch(`${HOST_NAME}/api/v1/User/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((text) => {
            throw new Error(text);
          });
        } else {
          return res.json();
        }
      })
      .then((result) => {
        setIsFormValid(true);
        authContext.login({}, result[0]);

        Swal.fire({
          // title: 'جهت مشاهده برنامه تمرینی خود از طریق لینک زیر وارد شوید:',
          title: "با موفقیت لاگین شدید",
          // html: '<a href="https://bettermove.ir/link/200" target="_blank"> لینک ورود به پنل</a>',
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "بستن",
          // cancelButtonText: 'لغو',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/boxList");
          } else {
            Swal.fire("تلاش دوباره", "درخواست تکراری", "error");
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "نام کاربری یا رمز عبور اشتباه است",
          icon: "error", // نوع آیکون
          showCancelButton: true, // نمایش دکمه انصراف
          showConfirmButton: false,
          cancelButtonText: "تلاش دوباره", // متن دکمه انصراف
          confirmButtonColor: "#3085d6", // رنگ دکمه تایید
          cancelButtonColor: "#d33", // رنگ دکمه انصراف
        }).then((result) => {
          if (result.isConfirmed) {
            // عملیات تایید
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // عملیات انصراف
          }
        });
      });
  };

  const onChangeHandler = () => {
    setIsGoogleRecaotchVerify(true);
  };

  return (
    <MuiRTL>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          {/* <video
            autoPlay
            loop
            muted
            style={styles.videoBackground}
          >
            <source src="./videos/istockphoto.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            className="blurredBackground"
          >
            <div className="login-form" style={{ padding: "2rem" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  sx={{
                    m: 1,
                    bgcolor: "#2f9bfa",
                    width: 100, // عرض مورد نظر
                    height: 100,
                    objectFit: "cover",
                  }}
                  // src='images/bettermove.png'
                >
                  <LockOutlinedIcon />
                </Avatar>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ fontFamily: "IRANSans", color: "#042b89" }}
                >
                  ورود به سیستم
                </Typography>
              </div>

              <form noValidate onSubmit={handleSubmit}>
                {/* <TextField
                  style={{ fontFamily: 'IRANSans' }}
                  margin="normal"
                  required
                  fullWidth
                  id="userName"
                  label="نام کاربری"
                  name="userName"
                  autoFocus
                /> */}

                {/* <TextField
                  style={{ fontFamily: 'IRANSans' }}
                  margin="normal"
                  required
                  fullWidth
                  id="extractedNumber"
                  label="عدد استخراج شده"
                  name="extractedNumber"
                  value={userLoginCode} // استفاده از extractedNumber برای نمایش در TextField
                  disabled // غیرفعال کردن ویرایش این فیلد
                /> */}

                <TextField
                  style={{ fontFamily: "IRANSans" }}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="رمز"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                {/* <div style={{ marginTop: '1rem' }}>
                  <ReCAPTCHA
                  sitekey=" 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={onChangeHandler}
                />
                </div> */}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  className="login-form-btn-successfuly"
                  // className={`${
                  //   (isGoogleRecaotchVerify)
                  //   ? 'login-form-btn-successfuly'
                  //   : 'login-form-btn-err'}`}
                  // disabled={(!isGoogleRecaotchVerify)}
                >
                  <p style={{ fontFamily: "IRANSans", color: "white" }}>ورود</p>
                </Button>

                {/* <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      <p style={{ fontFamily: 'IRANSans' }}>رمز خود را فراموش کردید ؟</p>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      <p style={{ fontFamily: 'IRANSans' }}>{"اکانت ندارید؟ میخواید ثبت نام کنید؟"}</p>
                    </Link>
                  </Grid>
                </Grid> */}
              </form>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    </MuiRTL>
  );
}
