import React, { createContext, useState } from 'react';

export const CardContext = createContext();

const CardProvider = ({ children }) => {

  const storedselectedAddCardValue = localStorage.getItem('storedselectedAddCardValue');
  const [selectedAddCard, setselectedAddCard] = useState([]);

  const updateselectedAddCardVariable = (newValue) => {
    localStorage.setItem('storedselectedAddCardValue', newValue);
    setselectedAddCard(newValue);
  };

  return (
    <CardContext.Provider value={{ selectedAddCard, setselectedAddCard,updateselectedAddCardVariable }}>
      {children}
    </CardContext.Provider>
  );
};

export { CardProvider };
