import React, { createContext, useState } from "react";

export const EducationSelectedInModalInexPage = createContext();

const EducationSelectedInModalInexPageProvider = ({ children }) => {
  const [educationSelected, setEducationSelected] = useState([]);

  return (
    <EducationSelectedInModalInexPage.Provider
      value={{ educationSelected, setEducationSelected }}
    >
      {children}
    </EducationSelectedInModalInexPage.Provider>
  );
};

export { EducationSelectedInModalInexPageProvider };
