
import React, { createContext, useState } from 'react';

export const CreatedNewExersiceCardContext = createContext();

const CreatedNewExersiceCardContextProvider = ({ children }) => {
  const [createdNewExersiceCard, setCreatedNewExersiceCard] = useState([]);

  return (
    <CreatedNewExersiceCardContext.Provider value={{ createdNewExersiceCard, setCreatedNewExersiceCard }}>
      {children}
    </CreatedNewExersiceCardContext.Provider>
  );
};

export { CreatedNewExersiceCardContextProvider };