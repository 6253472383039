import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// import Navbar from '../../Components/Navbar/Navbar';
import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";
import { PackagesMadeContext } from "../../Contexts/PackagesMade";
import { Box, Tabs, Tab, CircularProgress, Dialog } from "@mui/material";

import { CustomTabPanel } from "../../Components/Tab/Tab";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PublicIcon from "@mui/icons-material/Public";
import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import "./Templates.css";

import Swal from "sweetalert2";
import Treemenu from "../../Components/Treemenu/Treemenu";
import SingleTemplate from "../../Components/SingleTemplate/SingleTemplate";
import { useNavigate } from "react-router-dom";
import { parseJwt } from "../../utils";
import { HOST_NAME } from "../../config";

export default function Templates() {
  const [templates, setTemplates] = useState([]);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const localStorageData = localStorage.getItem("user");

  const decodedUser = parseJwt(localStorageData);

  function handleSearch(searchValue) {
    if (searchValue.length >= 1) {
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        const token = parsedData.token;
        const pageSize = 8;
        // setIsLoading(true);

        fetch(
          // `${HOST_NAME}/api/v1/Education/GetBYFilter?pageNumber=${currentPage}&pageSize=${pageSize}&stringFilter=${searchValue}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            // setListContent(data.educationlist);
            // setIsLoading(false);
            // setCountPage(Math.ceil(data.totalcount / pageSize));
          })
          .catch((e) => console.log(e));
      }
    } else {
      // getEducationListCondition();
    }
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const [treeMenuDataExerciseCartData, setTreeMenuDataExerciseCartData] =
    useState([]);

  // useEffect(() => {
  //   getAllTreeMenuDataExerciseCartDatas();
  // }, []);

  useEffect(() => {
    value === 1 ? getAllTemplates(false) : getAllTemplates(true);
  }, [value]);

  // const getAllTreeMenuDataExerciseCartDatas = () => {
  //   const localStorageData = localStorage.getItem("user");

  //   if (localStorageData) {
  //     const parsedData = JSON.parse(localStorageData);
  //     const token = parsedData.token;

  //     fetch("${HOST_NAME}/api/v1/ExerciseCtg", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         const treeMenuDataWithShowAll = [
  //           {
  //             keyID: -1,
  //             ctgName: "نمایش همه",
  //             fK_ParentID: null,
  //           },
  //           ...data,
  //         ];

  //         setTreeMenuDataExerciseCartData(treeMenuDataWithShowAll);
  //       })
  //       .catch(() => navigate("/"));
  //   }
  // };

  function getAllTemplates(isSite) {
    const localStorageData = localStorage.getItem("user");
    setIsTemplatesLoading(true);

    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/Template?isSite=${isSite}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("templates", data);
          setTemplates(data);
          setIsTemplatesLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  return (
    <>
      <ResponsiveNavbar />

      <MuiRTL>
        <Box className="templates-container" sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                icon={<PublicIcon style={{ marginInline: "0.5rem" }} />}
                iconPosition="start"
                label="الگوهای سایت"
                {...a11yProps(0)}
              />
              {decodedUser.UserName !== "admin" && (
                <Tab
                  icon={<EngineeringIcon style={{ marginInline: "0.5rem" }} />}
                  iconPosition="start"
                  label="الگوهای من"
                  {...a11yProps(1)}
                />
              )}
            </Tabs>
          </Box>
          {/* <button onClick={() => setIsOpenFilter(true)} className="filter-btn">
            <TuneOutlinedIcon /> فیلتر
          </button>

          <Dialog
            className="filter-dialog"
            open={isOpenFilter}
            onClose={() => setIsOpenFilter(false)}
          >
            <Treemenu data={treeMenuDataExerciseCartData} />
          </Dialog> */}
          {isTemplatesLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                margin: "1.5rem",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <CustomTabPanel value={value} index={0}>
                <div className="tab-container">
                  <div className="templates-wrapper">
                    {templates.map((data) => (
                      <SingleTemplate
                        key={data.keyID}
                        templateID={data.keyID}
                        setTemplates={setTemplates}
                        isValidateToDelete={decodedUser.UserName === "admin"}
                        {...data}
                      />
                    ))}
                  </div>
                </div>
              </CustomTabPanel>

              {decodedUser.UserName !== "admin" && (
                <CustomTabPanel value={value} index={1}>
                  <div className="tab-container">
                    {/* <div className="col-2 section-treemenu-tab2 tree-view">
                      <Treemenu data={treeMenuDataExerciseCartData} />
                    </div> */}
                    <div className="templates-wrapper">
                      {templates.map((data) => (
                        <SingleTemplate
                          key={data.keyID}
                          templateID={data.keyID}
                          isValidateToDelete={decodedUser.UserName !== "admin"}
                          setTemplates={setTemplates}
                          {...data}
                        />
                      ))}
                    </div>
                  </div>
                </CustomTabPanel>
              )}
            </>
          )}
        </Box>
      </MuiRTL>
    </>
  );
}
