import React, { useState, useEffect } from "react";
import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";

import "./Home.css";
import { Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Subscription from "../../Components/Subscription/Subscription";
import { HOST_NAME } from "../../config";

const services = [
  {
    id: 1,
    src: "/images/exercise.webp",
    title: "کتابخانه تمرین‌ها",
    desc: "دیگر هیچ تمرینی را فراموش نکنید! تمرین مورد نظر خود را به آسانی از بین مجموعه ویدیوهای تمرینی تهیه شده توسط تیم فیزیوتراپیست ما پیدا کنید، و هر ماه آماده ی بارگزاری تمرین های جدید باشید!",
  },
  {
    id: 2,
    src: "/images/register.jpg",
    title: "تجویز تمرین درمانی در 1 دقیقه! ",
    desc: "با ذخیره کردن تمرین ها در قالب برنامه های آماده، و یا استفاده از برنامه های پیش فرض ما، تنها با چند کلیک برنامه ی تمرینی را برای بیمار خود ارسال کنید. لینک دسترسی به تمرین ها پس از چند ثانیه برای بیمار اس ام اس می شود. پرینت گرفتن تمرین ها برای گذشته است!",
  },
  {
    id: 3,
    src: "/images/package.png",
    title: "محتوای آموزشی",
    desc: "ما فیزیوتراپیست هستیم و می دانیم فیزیوتراپیست ها به چه نیاز دارند! به فایل های آموزشی و پادکست تخصصی و مبتنی بر شواهد ما دسترسی داشته باشید، مقالات پیشنهاد شده دریافت کنید، و همینطور بخش آموزش درمان خود را با فایل های آموزش بیمار، با کیفیت بیشتری اجرا کنید.",
  },
  {
    id: 4,
    src: "/images/ticket.png",
    title: "ارتباط با بیمار",
    desc: "با استفاده ی آسان از سیستم پیام دهی ما، به راحتی با بیمار خود ارتباط داشته باشید و نیاز به استفاده از برنامه های پیام رسانی دیگر را حذف کنید.",
  },
];

export default function Home() {
  const [threeSubsciptions, setThreeSubscriptions] = useState([]);

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  function getAllSubscriptions() {
    const pageSize = 3;

    fetch(
      `${HOST_NAME}/api/v1/Service/GetActives?pageNumber=1&pageSize=${pageSize}`
    )
      .then((res) => res.json())
      .then((data) => {
        setThreeSubscriptions(data.servicesList);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  return (
    <>
      <ResponsiveNavbar />
      <Grid
        container
        className="hero"
        sx={{
          overflowY: { xs: "unset", md: "hidden" },
        }}
      >
        <Grid
          className="content-side"
          item
          xs={12}
          md={6}
          sx={{ order: { xs: 3, md: 1 } }}
        >
          <Box>
            <h1>
              آسان ترین روش تجویز تمرین درمانی <span>برای فیزیوتراپیست ها</span>
            </h1>
            <p>
              طراحی کنید، ارسال کنید و ارتباط برقرار کنید، همه در یک برنامه کامل
            </p>
            <div className="hero-links">
              <Link className="account" to="/register">
                ایجاد یک حساب کاربری
              </Link>
              <Link className="contact" to="/contact">
                تماس با ما
              </Link>
            </div>
          </Box>
        </Grid>
        <Grid item xs={9} sm={6} md={6} lg={5} sx={{ order: { xs: 1, md: 2 } }}>
          <img src="/images/hero.png" alt="" />
        </Grid>
      </Grid>

      <h2 className="home-header">خدمات ما</h2>
      <Grid
        container
        spacing={3}
        sx={{ maxWidth: "100%" }}
        className="services"
      >
        {services.map((service) => (
          <Grid key={service.id} item xs={12} sm={5} md={3} lg={3}>
            <Box className="service">
              <img src={service.src} alt="" />
              <h4>{service.title}</h4>
              <p>{service.desc}</p>
            </Box>
          </Grid>
        ))}

        <a className="link-to-subscriptions" href="#subscriptions">
          اشتراک رایگان 14 روزه را شروع کنید!
        </a>
      </Grid>

      <div className="demo">
        <img src="/images/demo.png" alt="demo" />
      </div>

      <h2 id="subscriptions" className="home-header">
        نمونه‌های اشتراک
      </h2>
      <Grid spacing={4} container className="subscriptions">
        {threeSubsciptions.map((subscription, index) => (
          <Grid
            className={
              threeSubsciptions.length === 3 && index === 1
                ? "active-parent"
                : null
            }
            key={subscription.keyID}
            item
            xs={12}
            sm={6}
            md={5}
            lg={3}
          >
            <Box
              style={{ height: "100%" }}
              className={
                threeSubsciptions.length === 3 && index === 1 ? "active" : null
              }
            >
              <Subscription {...subscription} listOrder={index + 1} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Link className="link-to-subscriptions" to="/subscriptions">
          مشاهده تمام اشتراک‌ها
        </Link>
      </div>

      <Footer />
    </>
  );
}
