import { useState, createContext } from "react";

export const SubscriptionContext = createContext();

export default function SubscriptionProvider({ children }) {
  const [choosedSubscriptions, setChoosedSubscriptions] = useState([]);
  const [orderKeyID, setOrderKeyID] = useState(0);
  const [hasActiveOrder, setHasActiveOrder] = useState(false);

  return (
    <SubscriptionContext.Provider
      value={{
        choosedSubscriptions,
        setChoosedSubscriptions,
        orderKeyID,
        setOrderKeyID,
        hasActiveOrder,
        setHasActiveOrder,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}
