import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import "./SelectBox.css";

export default function SelectBox({
  menuItems,
  filterName,
  filterValue,
  setFilterValue,
  children,
  type,
}) {
  return (
    <FormControl
      className="select-box"
      fullWidth
      variant="outlined"
      margin="normal"
    >
      <InputLabel id="filter-label" style={{ backgroundColor: "#F8F8F8" }}>
        {filterName}
      </InputLabel>

      <Select
        labelId="filter-label"
        value={filterValue}
        onChange={(e) => setFilterValue(e.target.value)}
        label="فیلتر براساس دسته بندی"
        style={{ fontFamily: "IRANSans", fontSize: "1.4rem" }}
      >
        {children}
        {menuItems.map((item) =>
          type === "patientList" ? (
            <MenuItem key={item.keyID} value={item.keyID}>
              {item.fName} {item.lName}
            </MenuItem>
          ) : type === "newTicket-patientList" ? (
            <MenuItem key={item.keyID} value={item.userid}>
              {item.fName} {item.lName}
            </MenuItem>
          ) : (
            <MenuItem key={item.id} value={item.value}>
              {item.name}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
