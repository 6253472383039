import React, { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import "./Register.css";

import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import "../LoginPage/Login.css";
import AuthContext from "../../Contexts/authContext";
import { Box, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

import { schema } from "../../utils";
import CustomCaptcha from "../../Components/CustomCaptcha/CustomCaptcha";
import { HOST_NAME } from "../../config";
const defaultTheme = createTheme();

const styles = {
  blurredBackground: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  videoBackground: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
};

export default function SignUpSide() {
  const [captchaText, setCaptchaText] = useState("");

  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const validationSchema = schema(captchaText);
    const formData = Object.fromEntries(data.entries());
    console.log(
      "form data captcha",
      formData.captcha,
      "shown captcha",
      captchaText
    );
    try {
      const isFormValid = await validationSchema.isValid(formData);
      if (isFormValid) {
        setIsLoading(true);
        const newUserInfos = {
          userName: formData.userName,
          password: formData.password,
          confirmPassword: formData.confirmPassword,
          role: "newuser",
          personKeyID: 0,
          personFullName: formData.phone,
        };

        fetch(`${HOST_NAME}/api/v1/User/Register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newUserInfos),
        })
          .then((res) => {
            console.log("resgister res", res);
            if (res.ok) {
              return res.json();
            }

            Swal.fire({
              icon: "warning",
              title: "کاربری با این اطلاعات از قبل وجود دارد",
            });

            return false;
          })
          .then((result) => {
            if (result) {
              authContext.login(result?.user, result?.accessToken);

              Swal.fire({
                title: "با موفقیت ثبت نام شدید",
                icon: "success",
                confirmButtonText: "ورود به لاگین",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/login");
                } else {
                  Swal.fire("تلاش دوباره", "درخواست تکراری", "error");
                }
              });
            }
          })
          .finally(() => setIsLoading(false));
        setFormErrors({});
      } else {
        await validationSchema.validate(formData, { abortEarly: false });
      }
    } catch (errors) {
      const newErrors = {};

      errors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      console.log("errors", newErrors);
      setFormErrors(newErrors);
    }
  }

  return (
    <>
      {/* <ResponsiveNavbar /> */}
      <MuiRTL>
        <ThemeProvider theme={defaultTheme}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <video autoPlay loop muted style={styles.videoBackground}>
              <source src="./videos/istockphoto.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
              className="blurredBackground"
            >
              <div className="login-form" style={{ padding: "2rem" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* <Avatar sx={{ m: 1, bgcolor: "#2f9bfa" }}>
                    <LockOutlinedIcon />
                  </Avatar> */}

                  <Link to="/" className="logo" />
                  <Typography
                    component="h1"
                    variant="h5"
                    style={{ fontFamily: "IRANSans", color: "#042b89" }}
                  >
                    ثبت نام در سیستم
                  </Typography>
                </div>

                <form noValidate onSubmit={handleSubmit}>
                  <TextField
                    style={{ fontFamily: "IRANSans" }}
                    margin="normal"
                    required
                    fullWidth
                    id="userName"
                    label="نام کاربری"
                    name="userName"
                    autoFocus
                  />
                  {formErrors.userName && (
                    <span className="register-err">{formErrors.userName}</span>
                  )}
                  <TextField
                    style={{ fontFamily: "IRANSans" }}
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="رمز"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formErrors.password && (
                    <span className="register-err">{formErrors.password}</span>
                  )}

                  <TextField
                    style={{ fontFamily: "IRANSans" }}
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="تکرار رمز"
                    type={showPassword ? "text" : "password"}
                    id="confirmPassword"
                    autoComplete="current-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formErrors.confirmPassword && (
                    <span className="register-err">
                      {formErrors.confirmPassword}
                    </span>
                  )}

                  <TextField
                    style={{ fontFamily: "IRANSans" }}
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="تلفن همراه"
                    name="phone"
                    autoFocus
                  />
                  {formErrors.phone && (
                    <span className="register-err">{formErrors.phone}</span>
                  )}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <TextField
                      style={{ fontFamily: "IRANSans" }}
                      margin="normal"
                      required
                      fullWidth
                      id="captcha"
                      name="captcha"
                      label="کد کپچا"
                      autoFocus
                    />
                    <CustomCaptcha setCaptchaText={setCaptchaText} />
                  </div>
                  {formErrors.captcha && (
                    <span className="register-err">{formErrors.captcha}</span>
                  )}

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {isLoading ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <CircularProgress style={{ color: "#fff" }} />
                      </Box>
                    ) : (
                      <p style={{ fontFamily: "IRANSans", color: "white" }}>
                        ورود
                      </p>
                    )}
                  </Button>

                  <Grid container>
                    {/* <Grid item xs>
                      <Link href="#" variant="body2">
                        <p style={{ fontFamily: "IRANSans" }}>
                          رمز خود را فراموش کردید ؟
                        </p>
                      </Link>
                    </Grid> */}
                    <Grid item>
                      <Link to="/login" variant="body2">
                        <p
                          style={{ fontFamily: "IRANSans", fontSize: "1.5rem" }}
                        >
                          حساب دارید؟ وارد شوید
                        </p>
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
          </Grid>
        </ThemeProvider>
      </MuiRTL>
    </>
  );
}
