import React, { createContext, useState } from 'react';

export const SelectedClientContext = createContext();

const SelectedClientProvider = ({ children }) => {
  const [selectedClient, setSelectedClient] = useState([]);

  return (
    <SelectedClientContext.Provider value={{ selectedClient, setSelectedClient }}>
      {children}
    </SelectedClientContext.Provider>
  );
};

export { SelectedClientProvider };