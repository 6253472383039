import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { SubscriptionContext } from "../../Contexts/Subscriptions";
import { HOST_NAME } from "../../config";

export default function PaymentVerify() {
  const orderKey = localStorage.getItem("value");
  const { orderKeyID, setOrderKeyID, setChoosedSubscriptions } =
    useContext(SubscriptionContext);

  const [params] = useSearchParams();
  const [isFree, setIsFree] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);

  // console.log("params", params.get("Status"), orderKeyID);

  useEffect(() => {
    const status = params.get("Status");
    if (status === "OK") {
      verifyPayment();
      setIsVerified(true);
    } else if (status === "free") {
      setIsFree(true);
    } else {
      setIsVerified(false);
    }
  }, []);

  useEffect(() => {
    if (isVerified) {
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        const token = parsedData.token;

        fetch(`${HOST_NAME}/api/v1/Order/${orderKey}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            console.log("verify payment res", res);

            return res.json();
          })
          .then(({ result, token }) => {
            console.log("order infoo", result);
            localStorage.setItem("user", JSON.stringify({ token }));

            setOrderInfo(result);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  }, [isVerified]);

  function verifyPayment() {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(
        `${HOST_NAME}/api/v1/Payment/verify?authority=${params.get(
          "Authority"
        )}&orderKeyID=${orderKey}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          console.log("verify payment res", res);
          setChoosedSubscriptions([]);
          return res.text();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  return (
    <div className="payment-verify-container">
      <div className="order-info-container">
        {isFree ? (
          <>
            <img src="/images/order-info.webp" alt="ثبت موفقیت آمیز سفارش" />
            <h1 style={{ color: "rgb(0, 209, 139)" }}>
              اشتراک رایگان برای شما با موفقیت ثبت شد.
            </h1>
            <Link to="/">بازگشت به خانه</Link>
          </>
        ) : isVerified ? (
          <>
            <img src="/images/order-info.webp" alt="ثبت موفقیت آمیز سفارش" />
            <h1 style={{ color: "rgb(0, 209, 139)" }}>
              سفارش {orderInfo?.serviceName} با موفقیت ثبت شد.
            </h1>
            <h3>کد پیگیری: {orderInfo?.refId}</h3>
            <Link to="/">بازگشت به خانه</Link>
          </>
        ) : (
          <>
            <img src="/images/error-payment.jpg" alt="خطا در پرداخت" />
            <h1 style={{ color: "#EC0000" }}>پرداخت شما لغو شد</h1>
            <h3>پرداخت شما انجام نشد. لطفاً دوباره تلاش کنید.</h3>
            <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
              <Link to="/">بازگشت به خانه</Link>
              <Link to="/basket">بازگشت به سبد خرید</Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
