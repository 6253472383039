import React from "react";
import RightSide from "../../Components/RightSide/RightSide";
import LeftSide from "../../Components/LeftSide/LeftSide";
import "./Index.css";

import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";

export default function Index() {
  return (
    <>
      <ResponsiveNavbar />
      <div className="mainSection build-page">
        <RightSide />
        <LeftSide />
      </div>
    </>
  );
}
