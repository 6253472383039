import React from "react";
import "./DisplayImages.css";

export default function DisplayImages({ item }) {
  function getFormat(file) {
    return file.mainPicturePath?.split(".").pop();
  }

  return ["jpg", "jpeg", "png", "gif", "webp"].includes(getFormat(item)) ? (
    <img
      className="img-format"
      src={item.mainPicturePath}
      alt={`file ${item.title}`}
    />
  ) : ["mp4", "avi", "mkv", "wmv", "webm", "mpeg", "mpg"].includes(
      getFormat(item)
    ) ? (
    <embed src={item.mainPicturePath} width="140px" height="80px" />
  ) : getFormat(item) === "pdf" ? (
    <img src="images/images.jfif" alt={`file ${item.title}`} />
  ) : getFormat(item) === "xlsx" ? (
    <img src="images/excel logo.png" alt={`file ${item.title}`} />
  ) : (
    <img src="images/file logo.png" alt={`file ${item.title}`} />
  );
}
