import React, { useState, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";

import "./packageContent.css";

export default function PackageContent({
  onClose,
  setContent,
  type,
  onDevider = null,
  isEditingTabPanel,
  coverContent,
}) {
  const [contentTitle, setContentTitle] = useState(
    coverContent.contentTitle || ""
  );
  const [contentDescription, setContentDescription] = useState(
    coverContent.contentDescription || ""
  );

  useEffect(() => {
    setContent({
      contentTitle: contentTitle.trim(),
      contentDescription: contentDescription.trim(),
    });
  }, [contentTitle, contentDescription]);

  function handleAddDevider() {
    onDevider();
    setContentTitle("");
    setContentDescription("");
  }

  return (
    <div className="content-section">
      {!isEditingTabPanel && (
        <button className="close-content" onClick={() => onClose(true)}>
          <CancelIcon style={{ color: "rgba(176,56,60,255)" }} />
        </button>
      )}
      <input
        type="text"
        value={contentTitle}
        onChange={(e) => setContentTitle(e.target.value)}
        className="content-section-title"
        placeholder={`عنوان ${type === "cover" ? "پکیج" : "تقسیم بندی"}`}
      />
      <input
        type="text"
        value={contentDescription}
        onChange={(e) => setContentDescription(e.target.value)}
        placeholder={`توضیحات ${type === "cover" ? "پکیج" : "تقسیم بندی"}`}
      />
      {type !== "cover" && (
        <button onClick={handleAddDevider} className="btn-add-devider">
          اضافه کردن به پکیج
        </button>
      )}
    </div>
  );
}
