import React, { useState, useEffect, useContext } from "react";
import { TreeView } from "@mui/x-tree-view";
import { TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { useSpring, animated } from "react-spring";
import ListIcon from "@mui/icons-material/List";
import Checkbox from "@mui/material/Checkbox";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// Context
import { CardExersiceContext } from "./../../Contexts/CardsExesciseContext";

import "./Treemenu.css";

export default function Treemenu({ data, children, pageUsed }) {
  const { setKeyIdItemsCardExersice, setKeyIdItemsEducation } =
    useContext(CardExersiceContext);

  // Checkbox state
  const [isMainCheckboxChecked, setIsMainCheckboxChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [expanded, setExpanded] = useState([-1]);

  const exercisePage = "exercises";

  const handleCheckboxToggle = (nodeId) => {
    if (pageUsed === exercisePage) {
      if (checkedItems.includes(nodeId)) {
        setCheckedItems((prevState) => prevState.filter((id) => id !== nodeId));
        setKeyIdItemsCardExersice((prevState) =>
          prevState.filter((id) => id !== nodeId)
        );
      } else {
        setCheckedItems((prevState) => [...prevState, nodeId]);
        setKeyIdItemsCardExersice((prevState) => [...prevState, nodeId]);
      }
    }
  };

  const handleToggle = (nodeId) => {
    if (expanded.includes(nodeId)) {
      setExpanded(expanded.filter((id) => id !== nodeId));
    } else {
      setExpanded([...expanded, nodeId]);
    }

    if (pageUsed === exercisePage) {
      !isMainCheckboxChecked && setKeyIdItemsCardExersice([nodeId]);
    } else {
      setKeyIdItemsEducation(nodeId);
    }
  };

  const renderTreeItems = (items) => {
    if (items) {
      return items.map((item) => (
        <TreeItem
          style={{ margin: "1rem" }}
          key={item.keyID}
          nodeId={item.keyID.toString()}
          onClick={(event) => {
            event.stopPropagation();
            handleToggle(item.keyID);
          }}
          expanded={expanded.includes(item.keyID).toString()}
          collapseIcon={<ExpandMoreIcon />}
          expandIcon={<ChevronLeft />}
          label={
            isMainCheckboxChecked === false ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                {item.fK_ParentID && (
                  <FiberManualRecordIcon
                    style={{ marginLeft: "5px", fontSize: "1rem" }}
                  />
                )}
                {item.ctgName}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {item.fK_ParentID && (
                  <FiberManualRecordIcon style={{ fontSize: "1rem" }} />
                )}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={checkedItems.includes(item.keyID)}
                    onChange={() => handleCheckboxToggle(item.keyID)}
                  />
                  {item.ctgName}
                </div>
              </div>
            )
          }
        >
          {item.fK_ParentID === null
            ? renderTreeItems(getChildren(item.keyID))
            : null}
        </TreeItem>
      ));
    }
  };

  const getChildren = (parentID) => {
    if (data) {
      return data.filter((item) => item.fK_ParentID === parentID);
    }
  };

  const [isIncreasing, setIsIncreasing] = useState(true);

  const style = useSpring({
    reverse: isIncreasing,
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    onRest: () => {
      setIsIncreasing(!isIncreasing);
    },
  });

  return (
    <TreeView
      aria-label="multi-select"
      sx={{
        height: "100vh",
        flexGrow: 1,
        maxWidth: 400,
        overflowY: "auto",
        fontFamily: "IRANSans",
        fontSize: "1.2rem",
      }}
    >
      <animated.div style={style}>
        <ListIcon
          style={{
            margin: "1rem",
            color: "blue",
            textAlign: "center",
            fontSize: "2rem",
          }}
        />
      </animated.div>
      <Checkbox
        checked={isMainCheckboxChecked}
        onChange={() => setIsMainCheckboxChecked((prevState) => !prevState)}
      >
        {" "}
        وضعیت منو
      </Checkbox>
      {renderTreeItems(getChildren(null))}

      {children}
    </TreeView>
  );
}
