import React, { useEffect, useState, useContext } from "react";
import "./RightSideEducationPage.css";
import Treemenu from "../Treemenu/Treemenu";
import SearchBar from "../SearchBar/SearchBar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import MuiRTL from "../MuiRTL/MuiRTL";
import { CardExersiceContext } from "./../../Contexts/CardsExesciseContext";
import { PackageID } from "../../Contexts/PackageID";
import Dialog from "@mui/material/Dialog";
import { EducationSelectedInModalInexPage } from "../../Contexts/EducationSelectedInModalInexPageContext";
import MuiAlert from "@mui/material/Alert";
import DisplayImages from "../DisplayImages/DisplayImages";
import useEducationFiles from "../../Hooks/useEducationFiles";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { HOST_NAME } from "../../config";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function RightSideEducationPage({ isLoadListEducation }) {
  const {
    setEducationID,
    educationData,
    setEducationData,
    educationFiles,
    setEducationFiles,
  } = useEducationFiles();
  const [listContent, setListContent] = useState([]);
  const [countPage, setCountPage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [treeMenuDataEducation, setTreeMenuDataEducation] = useState("");
  const [educationsMainPicture, setEducationsMainPicture] = useState([]);
  const { keyIdItemsEducation, setKeyIdItemsEducation } =
    useContext(CardExersiceContext);
  const { packageID } = useContext(PackageID);
  const [dataCount, setDataCount] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [editedSubDescription, setEditedSubDescription] = useState("");
  const [file, setFile] = useState(null);
  const [editedImage, setEditedImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);

  const { educationSelected, setEducationSelected } = useContext(
    EducationSelectedInModalInexPage
  );
  const [isSelected, setIsSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [seleEduKeyIds, setSeleEduKeyIds] = useState([]);
  const [isItemConfirmrd, setIsItemConfirmrd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  function getFormat(file) {
    return file.mainPicturePath.split(".").pop();
  }

  useEffect(() => {
    getAllDataTreeMenu();
  }, []);

  const getAllDataTreeMenu = () => {
    const localStorageData = localStorage.getItem("user");

    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/EducationCtg`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const treeMenuDataWithShowAll = [
            {
              keyID: -1,
              ctgName: "نمایش همه",
              fK_ParentID: null,
            },
            ...data,
          ];

          setTreeMenuDataEducation(treeMenuDataWithShowAll);
        });
    }
  };

  const getEducationList = (keyId, pageNumber, package_ID) => {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;
      setIsLoading(true);

      const pageSize = 9;
      const keyIDArray = Array.isArray(keyId) ? keyId : [keyId];
      const keyIDString = keyIDArray.join(" ");
      fetch(
        `${HOST_NAME}/api/v1/Education/${
          keyIDString ? keyIDString : "-1"
        },${pageNumber},${pageSize},${package_ID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setListContent(data.educationlist);
          setIsLoading(false);
          setCountPage(Math.ceil(data.totalcount / pageSize));

          setDataCount(data.totalcount);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    getEducationListCondition();
  }, [currentPage, keyIdItemsEducation, packageID]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    setItemList(listContent);
  }, [listContent]);

  // const handleRowClick = (id, event, item) => {
  //   event.preventDefault();
  //   const updatedData = listContent.map((item) =>
  //     item.id === id ? { ...item, isSelected: !item.isSelected } : item
  //   );
  //   setIsSelected(true);
  //   setListContent(updatedData);

  //   setSelectedItem(item);
  //   setEditedTitle(item.title);
  //   setEditedDescription(item.content);
  //   setEditedSubDescription(item.subContent);
  //   setEditedImage(item.mainPicturePath);
  //   setImagePreview(item.mainPicturePath);
  //   setOpen(true);
  // };

  const handleSubDescriptionChange = (event) => {
    setEditedSubDescription(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setEditedImage(URL.createObjectURL(file));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleConfirmClick = (id) => {
    const updatedItem = {
      keyID: id,
      title: editedTitle,
      content: editedDescription,
      subTitle: "string",
      subContent: "string",
      educationDate: new Date().toISOString(),
      educationCategoryID: keyIdItemsEducation,
    };

    // FETCH EDIT EDUCATION

    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/Education/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedItem),
      })
        .then((result) => {
          getEducationListCondition();
        })
        .catch((error) => {
          console.error("خطا در ارتباط با سرور: ", error);
        });
    }

    setSeleEduKeyIds([...seleEduKeyIds, updatedItem.keyID]);

    // Update the item in the itemList array
    if (listContent) {
      const updatedItemList = listContent.map((item) =>
        item.keyID === updatedItem.keyID ? updatedItem : item
      );
      // Update the itemList with the edited values
      setItemList(updatedItemList);
    }
    // setOpen(false);
  };

  const [openAlert, setOpenAlert] = React.useState(false);

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    if (isLoadListEducation) {
      getEducationListCondition();
    }
  }, [isLoadListEducation]);

  function getEducationListCondition() {
    if (packageID) {
      if (!currentPage) {
        getEducationList(keyIdItemsEducation, 1, packageID);
      } else {
        getEducationList(keyIdItemsEducation, currentPage, packageID);
      }
    } else {
      if (!currentPage) {
        getEducationList(keyIdItemsEducation, 1, 0);
      } else {
        getEducationList(keyIdItemsEducation, currentPage, 0);
      }
    }
  }

  const handleSearch = (searchValue) => {
    if (searchValue.length >= 1) {
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        const token = parsedData.token;
        const pageSize = 8;
        setIsLoading(true);

        fetch(
          `${HOST_NAME}/api/v1/Education/GetBYFilter?pageNumber=${currentPage}&pageSize=${pageSize}&stringFilter=${searchValue}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setListContent(data.educationlist);
            setIsLoading(false);
            setCountPage(Math.ceil(data.totalcount / pageSize));
          })
          .catch((e) => console.log(e));
      }
    } else {
      getEducationListCondition();
    }
  };

  useEffect(() => {
    // console.log("listContent", listContent);
  }, [listContent]);

  const downloadImage = (fileAddress) => {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(fileAddress, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.jpg");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading the image:", error);
        });
    }
  };
  return (
    <div
      className="right-side__mainSection"
      style={{
        display: "flex",
        padding: "1.5rem",
        borderLeft: "2px dashed rgba(0, 0, 0, 0.12)",
      }}
    >
      <div className="treeMenu__education-section">
        <Treemenu data={treeMenuDataEducation} />
      </div>
      <div className="showInformation__education-section">
        <SearchBar handleSearch={handleSearch} />

        <div className="education-page-options">
          <button onClick={() => setIsOpenFilter(true)} className="filter-btn">
            <TuneOutlinedIcon /> فیلتر
          </button>

          <Dialog
            className="filter-dialog"
            open={isOpenFilter}
            onClose={() => setIsOpenFilter(false)}
          >
            <Treemenu data={treeMenuDataEducation} />
          </Dialog>
          <span>همه ی موارد پیدا شده : {dataCount}</span>
        </div>

        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              margin: "1rem",
            }}
          >
            ` <CircularProgress />
          </Box>
        ) : (
          <>
            {/* <div
              style={{
                backgroundColor: "white",
                padding: "0.5rem",
                margin: "2rem",
              }}
            >
              <div>
                <MuiRTL>
                  <List>
                    {listContent?.map((item) => (
                      <ListItem
                        onClick={(e) => {
                          handleRowClick(item.keyID, e, item);
                        }}
                        key={item.keyID}
                        button
                      >
                        <ListItemText primary={item.title} />
                        {isItemConfirmrd && <HistoryEduIcon />}
                      </ListItem>
                    ))}
                  </List>

                  <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogContent fullWidth="lg">
                      <TextField
                        style={{ fontFamily: "IRANSans" }}
                        fullWidth
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                      />
                      <TextField
                        style={{ fontFamily: "IRANSans" }}
                        fullWidth
                        multiline
                        rows={8}
                        value={editedDescription}
                        onChange={(e) => setEditedDescription(e.target.value)}
                      />

                      <div>
                        {imagePreview && (
                          <img
                            src={imagePreview}
                            alt="Preview"
                            style={{ width: "30%" }}
                          />
                        )}
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          handleConfirmClick(selectedItem.keyID);
                          setEducationSelected([
                            ...educationSelected,
                            selectedItem,
                          ]);
                        }}
                        style={{ backgroundColor: "#1976d2" }}
                      >
                        تایید
                      </Button>
                      <Button
                        onClick={() => setOpen(false)}
                        style={{ backgroundColor: "#ff8a8a" }}
                      >
                        بستن
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Stack spacing={2} sx={{ width: "100%" }}>
                    <Snackbar
                      open={openAlert}
                      autoHideDuration={6000}
                      onClose={handleCloseAlert}
                    >
                      <Alert
                        onClose={handleCloseAlert}
                        severity="success"
                        style={{
                          fontFamily: "IRANSans",
                          fontSize: "1.5rem",
                          width: "100%",
                        }}
                      >
                        {selectedItem && selectedItem.title} ویرایش شد !
                      </Alert>
                    </Snackbar>
                  </Stack>
                </MuiRTL>
              </div>
            </div> */}
            <div className="educations-container">
              {listContent?.map((item) => (
                <React.Fragment key={item.keyID}>
                  <div
                    onClick={() => {
                      setEducationID(item.keyID);
                      setEducationData(item);
                    }}
                    className="education-wrapper"
                  >
                    <div className="education-images-container">
                      <DisplayImages item={item} />
                    </div>

                    <div className="single-education-content">
                      <span className="education-title">{item.title}</span>
                      <span className="education-format">
                        نوع فایل: ({getFormat(item)})
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>

            <Dialog
              className="education-file-dialog"
              open={educationFiles}
              onClose={() => {
                setEducationID(0);
                setEducationData(null);
                setEducationFiles(null);
              }}
            >
              <div className="education-file-header">
                <span>{educationData?.title}</span>
                <p>{educationData?.content}</p>
              </div>

              <ul className="files-container">
                {educationFiles?.map((file) => (
                  <li>
                    <a
                      download={`${file.fileName}.${file.fileType}`}
                      href={file.filesAddress}
                    >
                      {file.fileName}.{file.fileType}
                    </a>
                  </li>
                ))}
              </ul>
            </Dialog>
            <div>
              <MuiRTL>
                <Stack
                  spacing={2}
                  direction="rtl"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px",
                  }}
                >
                  <Pagination
                    count={countPage}
                    color="primary"
                    onChange={handlePageChange}
                  />
                </Stack>
              </MuiRTL>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
