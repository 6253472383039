import React, { useState, useEffect } from "react";
import "./Tickets.css";
import { Add, Delete } from "@mui/icons-material";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
} from "@mui/material";
import Swal from "sweetalert2";
import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";
import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import SelectBox from "../../Components/SelectBox/SelectBox";
import NewTicketModal from "../../Components/NewTicketModal/NewTicketModal";
import ChatBox from "../../Components/ChatBox/ChatBox";
import { Stack } from "react-bootstrap";
import Loader from "../../Components/Loader/Loader";
import SingleTicketRow from "../../SingleTicketRow/SingleTicketRow";
import { HOST_NAME } from "../../config";

export default function Tickets() {
  const [ticketFilter, setTicketFilter] = useState("");
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [isLoadingTickets, setIsLoadingTickets] = useState(false);
  const [activeTicket, setActiveTicket] = useState();
  const [isNewTicketBoxOpen, setIsNewTicketBoxOpen] = useState(false);
  const [ticketReceiver, setTicketReceiver] = useState(null);
  const [ticketReceiverType, setTicketReceiverType] = useState("1");
  const [allTickets, setAllTickets] = useState([]);
  const [allPatients, setAllPatients] = useState([]);
  const [countPage, setCountPage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [opponent, setopponent] = useState({
    userID: -1,
    fullname: "",
  });
  const [starterUser, setStarterUser] = useState({
    userID: -1,
    fullname: "",
  });

  const singleTicketRowProps = {
    setopponent,
    setStarterUser,
    setActiveTicket,
  };

  const chatBoxProps = {
    activeChat: activeTicket,
    starterUser,
    opponent,
  };

  useEffect(() => {
    getAllPatients();
  }, []);

  useEffect(() => {
    if (ticketFilter) {
      setCurrentPage(1);
      getTicketsByPatientFilter(1);
    } else {
      getAllTickets(currentPage);
    }
  }, [ticketFilter]);

  useEffect(() => {
    if (ticketFilter) {
      getTicketsByPatientFilter(currentPage);
    } else {
      getAllTickets(currentPage);
    }
  }, [currentPage]);

  function createNewTicketBox() {
    setActiveTicket(null);
    setIsNewTicketBoxOpen(true);
  }

  function getAllPatients() {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;
      fetch(`${HOST_NAME}/api/v1/Patient/1,10000`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setAllPatients(data.patientModelList))
        .catch((error) => {
          console.error("خطا در ارتباط با سرور: ", error);
        });
    }
  }

  function getTicketsByPatientFilter(pageNumber) {
    const localStorageData = localStorage.getItem("user");
    setIsLoadingTickets(true);
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;
      const pageSize = 4;

      fetch(
        `${HOST_NAME}/api/v1/Ticket/GetByPatientID?patientKeyID=${ticketFilter}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("all filterd tickets", data);
          setAllTickets(data);

          setCountPage(Math.ceil(data.totalcount / pageSize));
        })
        .catch((error) => {
          setAllTickets([]);
          setCountPage(0);
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoadingTickets(false);
        });
    }
  }

  function getAllTickets(pageNumber) {
    const localStorageData = localStorage.getItem("user");
    setIsLoadingTickets(true);
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;
      const pageSize = 4;

      fetch(
        `${HOST_NAME}/api/v1/Ticket/${pageNumber},${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("all tickets", data);
          setAllTickets(data);
          setIsLoadingTickets(false);
          setCountPage(Math.ceil(data.totalcount / pageSize));
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  function deleteTicket(e) {
    e.stopPropagation();
    Swal.fire({
      title: `آیا مطمئن هستید که پیام حذف شود؟`,
      icon: "warning",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        const localStorageData = localStorage.getItem("user");
        if (localStorageData) {
          const parsedData = JSON.parse(localStorageData);
          const token = parsedData.token;
          // fetch(`${HOST_NAME}/api/v1/Patient/${id}`, {
          //   method: "DELETE",
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //   },
          // })
          //   .then((res) => console.log("response =+++..>", res))
          //   .catch((error) => {
          //     console.error("خطا در ارتباط با سرور: ", error);
          //   });
        }
      }
    });
  }

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <ResponsiveNavbar />
      <MuiRTL>
        <div className="tickets">
          <div className="tickets-right-side">
            <Grid
              container
              alignItems="stretch"
              style={{ padding: "2rem", gap: "1rem" }}
            >
              <Grid item xs={5} sm={3} style={{ maxWidth: "unset" }}>
                <button
                  onClick={createNewTicketBox}
                  className="client-add-ticket"
                >
                  <IconButton>
                    <Add fontSize="large" style={{ color: "white" }} />
                  </IconButton>
                  پیام جدید
                </button>
              </Grid>
              <Grid item xs={5} sm={3} style={{ maxWidth: "unset" }}>
                <div className="tickets-count">
                  <ConfirmationNumberOutlinedIcon />
                  <div>
                    <span className="ticket-count-title">همه پیام ها</span>
                    <span>{allTickets?.totalcount} عدد</span>
                  </div>
                </div>
              </Grid>
              <Grid item sm={4} xs={12} style={{ maxWidth: "unset" }}>
                <SelectBox
                  filterName="فیلتر بر اساس نام بیمار"
                  type="patientList"
                  filterValue={ticketFilter}
                  setFilterValue={setTicketFilter}
                  menuItems={allPatients}
                >
                  <MenuItem value="">
                    <em>همه</em>
                  </MenuItem>
                </SelectBox>
              </Grid>
            </Grid>
            <div className="tickets-table-container">
              {!isLoadingTickets ? (
                <table className="tickets-table">
                  <thead>
                    <tr>
                      <th>فرستنده</th>
                      <th>گیرنده</th>
                      <th>موضوع</th>
                      <th>تاریخ ایجاد</th>
                      <th>تاریخ آپدیت</th>
                      <th>وضعیت</th>
                      {/* <th>حذف</th> */}
                    </tr>
                  </thead>
                  {allTickets && allTickets.ticketslist?.length > 0 ? (
                    <tbody>
                      {allTickets.ticketslist.map((ticket) => (
                        <SingleTicketRow
                          key={ticket.keyID}
                          {...ticket}
                          {...singleTicketRowProps}
                        />
                      ))}
                    </tbody>
                  ) : (
                    <tr>
                      <td
                        style={{
                          fontSize: "2rem",
                        }}
                        colSpan={6}
                      >
                        پیامی یافت نشد
                      </td>
                    </tr>
                  )}
                </table>
              ) : (
                <Loader />
              )}
            </div>

            {!isLoadingTickets ? (
              <MuiRTL>
                <Stack
                  spacing={2}
                  direction="rtl"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px auto",
                    width: "fit-content",
                  }}
                >
                  <Pagination
                    count={countPage}
                    color="primary"
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </Stack>
              </MuiRTL>
            ) : null}
          </div>
          {isLoadingChat ? <Loader /> : <ChatBox {...chatBoxProps} />}
        </div>

        <NewTicketModal
          isNewTicketBoxOpen={isNewTicketBoxOpen}
          setIsNewTicketBoxOpen={setIsNewTicketBoxOpen}
          receiverID={ticketReceiverType === "1" ? 1 : ticketReceiver}
          getAllTickets={() => getAllTickets(1)}
        >
          <div className="filter-section">
            <div className="filter-receiver-type">
              <SelectBox
                filterName="گیرنده پیام"
                filterValue={ticketReceiverType}
                setFilterValue={setTicketReceiverType}
                menuItems={[
                  { id: 1, name: "ادمین", value: "1" },
                  { id: 2, name: "بیمار", value: "patient" },
                ]}
              />
            </div>

            {ticketReceiverType !== "1" && (
              <SelectBox
                filterName="نام بیمار"
                filterValue={ticketReceiver}
                type="newTicket-patientList"
                setFilterValue={setTicketReceiver}
                menuItems={allPatients}
              />
            )}
          </div>
        </NewTicketModal>
      </MuiRTL>
    </>
  );
}
