import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton, Grid, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
// import MuiRTL from '../MuiRTL/MuiRTL';

// import './TextFildwithAddIcon.css';

const Parameters = ({ setParameters, link, keyID }) => {
  const [commands, setCommands] = useState([]);
  const [parametersIncluds, setParametersIncluds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataReceived, setDataReceived] = useState(false); // استیت برای نشان دادن دریافت داده‌ها
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');

  const handleTitleChange = (index, newTitle) => {
    const updatedCommands = [...commands];
    updatedCommands[index].paramName = newTitle;
    setCommands(updatedCommands);
  };

  const handleValueChange = (index, newValue) => {
    const updatedCommands = [...commands];
    updatedCommands[index].paramValue = newValue;
    setCommands(updatedCommands);
  };

  const handleParamNameChange = (keyID, newName) => {
    const updatedParametersIncluds = parametersIncluds.map(p => {
      if (p.keyID === keyID) {
        return { ...p, paramName: newName };
      }
      return p;
    });
    setParametersIncluds(updatedParametersIncluds);
  };
  
  const handleParamValueChange = (keyID, newValue) => {
    const updatedParametersIncluds = parametersIncluds.map(p => {
      if (p.keyID === keyID) {
        return { ...p, paramValue: newValue };
      }
      return p;
    });
    setParametersIncluds(updatedParametersIncluds);
  };



  const handleRemoveCommand = (index) => {
    const updatedCommands = commands.filter((_, i) => i !== index);
    setCommands(updatedCommands);
  };

  const handleRemoveParametersIncludes = (index) => {
    const updatedParametersIncluds = parametersIncluds.filter(i => i.keyID !== index);
    setParametersIncluds(updatedParametersIncluds);
  };
 

  // دریافت پرامترها از api
  const getApiData = (link) => {
    const localStorageData = localStorage.getItem('user');
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      setIsLoading(true);
      fetch(link, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(data => {
          setParametersIncluds(data);
          setIsLoading(false);
        })
        .catch(e => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (link) {
      getApiData(link);
    }
  }, [link]);

  useEffect(() => {
    if (parametersIncluds.length > 0) {
      setDataReceived(true);
    }
  }, [parametersIncluds]);


  return (
    
    <div>
      
      {/* <MuiRTL> */}
        {isLoading ? (
          <p>Loading...</p>
        ) :  (
          <>
      {commands.map((command, index) => (
        <Paper key={index} elevation={3} style={{ padding: '1rem', marginBottom: '1rem', marginTop: '1rem'}}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <TextField
                label="عنوان"
                variant="filled"
                fullWidth
                value={command.paramTitle}
                // onChange={(e) => handleTitleChange(index, e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="مقدار"
                variant="filled"
                fullWidth
                value={command.paramValue}
                // onChange={(e) => handleValueChange(index, e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => handleRemoveCommand(index)} color="secondary">
                <CancelIcon style={{ color: 'rgba(176,56,60,255)' }}/>
              </IconButton>
            </Grid>
          </Grid>

        </Paper>
      ))}

      {parametersIncluds && parametersIncluds.length? (
          parametersIncluds.map(p => (
            <Grid container spacing={2} alignItems="center" style={{ padding: '1rem'}}>
              <Grid item xs={6}>
                <TextField
                  style={{ fontFamily: 'IRANSans' }}
                  label="عنوان"
                  variant="filled"
                  fullWidth
                  value={p.paramName}
                  onChange={(e) => handleParamNameChange(p.keyID, e.target.value)}
                  // onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={{ fontFamily: 'IRANSans' }}
                  label="مقدار"
                  variant="filled"
                  fullWidth
                  value={p.paramValue}
                  onChange={(e) => handleParamValueChange(p.keyID, e.target.value)}
                  // onChange={(e) => setValue(e.target.value)}
                />
              </Grid>
              <Grid item xs={1}>
                {/* <IconButton onClick={handleAddCommand} color="primary">
                  <AddIcon fontSize='large'/>
                </IconButton> */}
                <IconButton onClick={() => handleRemoveParametersIncludes(p.keyID)} color="secondary">
                  <CancelIcon style={{ color: 'rgba(176,56,60,255)' }}/>
              </IconButton>
              </Grid>
            </Grid>

          ))) : 
         
              
            
          <Grid container spacing={2} alignItems="center" style={{ padding: '1rem'}}>
            
            <Grid item xs={6}>
              <TextField
                style={{ fontFamily: 'IRANSans' }}
                label="عنوان"
                variant="filled"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                style={{ fontFamily: 'IRANSans' }}
                label="مقدار"
                variant="filled"
                fullWidth
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              {/* <IconButton onClick={handleAddCommand} color="primary">
                <AddIcon fontSize='large'/>
              </IconButton> */}
            </Grid>
          </Grid>
        }

            {/* <div style={{ display: 'flex', margin: '1rem', flexDirection: 'column' }}>

                          <Button variant="outlined" color="success" onClick={() => handleAddCommand(title, value, keyID)} style={{ margin: '0.5rem', borderRadius: '0px' }}>
  <AddIcon fontSize='large' />
</Button>

              <Button variant="contained" color="primary" onClick={() => handleSubmit(keyID)} style={{ borderRadius: '0px' }}>
                <p style={{ color: 'wheat' }}>ثبت</p>
              </Button>
            </div> */}
          </>
        ) }
      {/* </MuiRTL> */}
    </div>
  );
};

export default Parameters;
