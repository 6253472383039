import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

export default function Footer() {
  return (
    <footer>
      <div className="wrapper">
        <img src="/images/bettermove.png" alt="" />
        <ul>
          <li>
            <Link to="/build">ساخت برنامه</Link>
          </li>
          <li>
            <Link to="/templates">قالب‌های آماده</Link>
          </li>
          <li>
            <Link to="/contact">تماس با ما</Link>
          </li>
          <li>
            <Link to="/register">ثبت نام</Link>
          </li>
        </ul>

        <p className="copy-right">
          &copy; تمامی حقوق این سایت متعلق به <Link to="/">Better Move</Link>{" "}
          می‌باشد. هرگونه استفاده از مطالب، تصاویر و محتوای سایت بدون کسب اجازه
          کتبی از مدیریت، پیگرد قانونی خواهد داشت.
        </p>
      </div>
    </footer>
  );
}
