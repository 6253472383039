import React, { useEffect, useState } from "react";
import { HOST_NAME } from "../config";

export default function useEducationFiles() {
  const [educationID, setEducationID] = useState(0);
  const [educationData, setEducationData] = useState(null);
  const [educationFiles, setEducationFiles] = useState(null);

  useEffect(() => {
    if (educationID && educationData) {
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        const token = parsedData.token;

        fetch(
          `${HOST_NAME}/api/v1/Education/${educationID}/EducationFiles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => setEducationFiles(data.result))
          .catch((error) => {
            console.error("خطا در ارتباط با سرور: ", error);
          });
      }
    }
  }, [educationID, educationData]);

  return {
    educationID,
    setEducationID,
    educationData,
    setEducationData,
    educationFiles,
    setEducationFiles,
  };
}
