import React from "react";

import MyTabs from "../AddTabs__LeftSide/Tabs_AddTabs";

import "./LeftSide.css";

export default function LeftSide() {
  return (
    <div className="left-side__mainSection">
      <div>
        <MyTabs />
      </div>
    </div>
  );
}
