import React, { useState, useEffect } from "react";

import { formatDate, formatTime, parseJwt } from "../utils";
import { HOST_NAME } from "../config";

export default function SingleTicketRow({
  setActiveTicket,
  changeDate,
  createDate,
  createByUserID,
  reciverID,
  hasNewmessage,
  subject,
  keyID,
  setopponent,
  setStarterUser,
  isFromClient,
}) {
  const [receiverName, setReceiverName] = useState("");
  const [starterUserName, setStarterUsername] = useState("");
  const [isNewMessage, setIsNewMessage] = useState(hasNewmessage);

  useEffect(() => {
    const localStorageData = localStorage.getItem("user");
    const user = parseJwt(localStorageData);
    if (createByUserID === user.UserID) {
      setStarterUsername("شما");
      getPatientByUserID(reciverID)
        .then((res) => res.json())
        .then((data) => setReceiverName(`${data.fName} ${data.lName}`))
        .catch(() => setReceiverName("ادمین"));
    } else {
      setReceiverName("شما");
      getPatientByUserID(createByUserID)
        .then((res) => res.json())
        .then((data) => setStarterUsername(`${data.fName} ${data.lName}`))
        .catch(() => setStarterUsername("ادمین"));
    }
  }, []);

  function getPatientByUserID(userID) {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      return new Promise((resolve, reject) => {
        fetch(`${HOST_NAME}/api/v1/Patient/Getbyguid?userID=${userID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  function showTicket() {
    setIsNewMessage(false);
    setActiveTicket(keyID);
    setopponent({
      userID: reciverID,
      fullname: receiverName,
    });
    setStarterUser({
      userID: createByUserID,
      fullname: starterUserName,
    });
  }
  return (
    <tr onClick={showTicket} className="ticket">
      {!isFromClient && (
        <>
          <td>{starterUserName}</td>
          <td>{receiverName}</td>
        </>
      )}

      <td className="ticket-subject">{subject}</td>
      <td>
        <span>
          {formatTime(createDate)} | {formatDate(createDate)}
        </span>
      </td>
      <td>
        <span>
          {formatTime(changeDate)} | {formatDate(changeDate)}
        </span>
      </td>
      <td>
        {isNewMessage ? (
          <span className="new-ticket-notif">جدید</span>
        ) : (
          "خوانده شده"
        )}
      </td>
      {/* <td>
        <Delete
          onClick={deleteTicket}
          className="delete-ticket"
          fontSize="large"
        />
      </td> */}
    </tr>
  );
}
