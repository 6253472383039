import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import SaveIcon from "@mui/icons-material/Save";
import { IconButton } from "@mui/material";

// Contexts
import { PackagesMadeContext } from "../../../Contexts/PackagesMade";
import Swal from "sweetalert2";
import { parseJwt } from "../../../utils";
import { PackageID } from "../../../Contexts/PackageID";
import { HOST_NAME } from "../../../config";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ModalIconSave({
  coverContent,
  setIsTabPanelOpen,
  isEditingTabPanel,
}) {
  const [open, setOpen] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const [decodedUser, setDecodedUser] = useState({});
  const { templateID, setTemplateID, packageID } = useContext(PackageID);
  const { packagesMade, setPackagesMade, exerciseParameters } =
    useContext(PackagesMadeContext);

  function sendParametersToTemplate(id) {
    const localStorageData = localStorage.getItem("user");

    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;
      console.log("packagesMade.exercises", packagesMade.exercises);

      const deviders = packagesMade.exercises
        ?.map((card, index) => ({
          keyID: 0,
          fK_TemplateID: id,
          title: card.contentTitle || card.title || "",
          instruction: card.contentDescription || card.instruction || "",
          rowIndex: index,
          type: card.type,
        }))
        .filter((devider) => devider.type === "devider");

      const editingDeviders = packagesMade.exercises
        .map((card, index) => ({
          keyID: card.keyID,
          fK_TemplateID: card.fK_TemplateID,
          title: card.title || "",
          instruction: card.instruction || "",
          rowIndex: index,
          type: card.type,
        }))
        ?.filter((devider) => devider.fK_TemplateID);
      // .map((devider) => ({ ...devider, fK_TemplateID: id }));

      console.log("deviders", deviders);
      console.log("editingDeviders", editingDeviders);

      if (templateID === -1) {
        console.log("===");
        [...deviders, ...editingDeviders].forEach((devider) => {
          fetch(`${HOST_NAME}/api/v1/Template/PostDevider`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(devider),
          })
            .then((result) => {
              setTemplateID(-1);
              console.log("res devider", result);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });
      } else {
        console.log("!==");
        editingDeviders.forEach((devider) => {
          fetch(`${HOST_NAME}/api/v1/Template/PutDevider`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(devider),
          })
            .then((result) => {
              setTemplateID(-1);
              console.log("res devider put", result);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });

        deviders.forEach((devider) => {
          fetch(`${HOST_NAME}/api/v1/Template/PostDevider`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(devider),
          })
            .then((result) => {
              setTemplateID(-1);
              console.log("res devider", result);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });
      }

      exerciseParameters.forEach((exercise) => {
        fetch(
          `${HOST_NAME}/api/v1/Template/${id}/Exercises/${exercise.exerciseID}/Parameters`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(
              exercise.params.map(
                ({ keyID, paramName, paramValue, paramType, isDefault }) => ({
                  keyID,
                  paramName,
                  paramValue,
                  paramType,
                  isDefault,
                })
              )
            ),
          }
        ).then((res) => {
          setTemplateID(-1);
          console.log("params res", res);
        });
      });
    }
  }

  const handleSavePackage = () => {
    console.log("first logg test => packages made", packagesMade);
    const newPackage = {
      ctgID: -1,
      exercisekeylist: packagesMade.exercises
        ?.map((card, index) => ({
          exerciseID: card.keyID,
          status: index,
          isCustomized: card.isCustom || card.isCustomized,
          type: card.type,
          fK_TemplateID: card.fK_TemplateID,
        }))
        // .filter((card) => !card.fK_TemplateID)
        .filter((card) => card.type !== "devider" && !card.fK_TemplateID),

      educationkeyList: packagesMade.educations?.map(
        (education) => education.keyID
      ),
      pkgDto: {
        keyID: templateID,
        packageName: coverContent.contentTitle || "بدون عنوان",
        packageDescription: coverContent.contentDescription || "",
      },
    };

    console.log("new template", newPackage);

    const localStorageData = localStorage.getItem("user");

    // sendParametersToTemplate();
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/Template`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPackage),
      })
        .then((res) => {
          Swal.fire({
            title: `الگو با موفقیت ${
              templateID !== -1 ? "ویرایش" : "ساخته"
            } شد`,
            icon: "success",
          });

          handleClose();
          setIsTabPanelOpen(false);
          console.log("res template creation");
          return res.json();
        })
        .then((id) => {
          console.log("template id", id);
          sendParametersToTemplate(id);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  useEffect(() => {
    const localStorageData = localStorage.getItem("user");

    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(
        `${HOST_NAME}/api/v1/Template?isSite=${
          decodedUser.UserName === "admin"
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setAllTemplates(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    setDecodedUser(parseJwt(localStorageData));
  }, []);

  function checkCoverContent() {
    if (coverContent.contentTitle === "" || !Object.keys(coverContent).length) {
      Swal.fire({
        title: "خطا",
        icon: "error",
        text: "از قسمت کاور نام پکیج خود را وارد کنید.",
      });
    } else if (
      allTemplates.find(
        (template) => template.packageName === coverContent.contentTitle
      )
    ) {
      Swal.fire({
        title: "خطا",
        icon: "error",
        text: "نام پکیح وارد شده تکراری است.",
      });
    } else if (
      !packagesMade.educations.length &&
      !packagesMade.exercises.length
    ) {
      Swal.fire({
        title: "خطا",
        icon: "error",
        text: "حداق یک تمرین یا آموزش به پکیج اضافه کنید",
      });
    } else {
      setOpen(true);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div
        onClick={isEditingTabPanel ? handleSavePackage : checkCoverContent}
        style={{ display: "flex", alignItems: "center" }}
      >
        <IconButton>
          <SaveIcon fontSize="large" />
        </IconButton>

        <p style={{ fontSize: "1.2rem" }}>
          {isEditingTabPanel ? "ویرایش" : "ذخیره"}
        </p>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{
            cursor: "move",
            color: "#1876d3",
            fontSize: "1.5rem",
            fontFamily: "IRANSans",
          }}
          id="draggable-dialog-title"
        >
          ذخیره ورزش
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "1.3rem", fontFamily: "IRANSans" }}
          >
            آیا می خواهید این پکیج را به عنوان یک الگو ذخیره کنید؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            انصراف
          </Button>
          <Button
            onClick={handleSavePackage}
            style={{ backgroundColor: "#1876d3" }}
          >
            <p style={{ color: "white" }}>بله</p>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
