import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import VideoModal from "../../Components/VideoModal/VideoModal";
import Parameters from "./Parameters";
import { HOST_NAME } from "../../config";

export default function CaptionFormCardForClient({ keyID, packageID }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState([]);
  const [parameters, setParameters] = useState(null);

  const [detailCardExerciseData, setDetailCardExerciseData] = useState({});
  const [fetchedData, setFetchedData] = useState(null);

  const [videos, setVideos] = useState([]);
  const [exerciseFiles, setExerciseFiles] = useState([]);
  const [exerciseParameters, setExerciseParameters] = useState([]);
  const [isLoding, setIsLoding] = useState(false);
  const [dataReceived, setDataReceived] = useState(false);

  const handleMediaClick = (media) => {
    setCurrentMedia(media);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentMedia([]);
  };

  // ** getInformationPopupCard
  const getInformationPopupCard = (keyID) => {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      setIsLoding(true);
      fetch(
        `${HOST_NAME}/api/v1/Package/${packageID}/Exercises/${keyID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setFetchedData(data);
          setIsLoding(false);
        })
        .catch((e) => {
          setIsLoding(true);
        });
    }
  };

  useEffect(() => {
    if (fetchedData) {
      setDetailCardExerciseData(fetchedData);
    }
  }, [fetchedData, setDetailCardExerciseData, detailCardExerciseData]);

  useEffect(() => {
    getInformationPopupCard(keyID);
  }, []);

  // ** ____________________________________________________________

  const {
    execution,
    links,
    mainPicturePath,
    name,
    otherDescription,
    preparation,
    subject1,
    subject2,
  } = detailCardExerciseData;

  //**  getDataOfLinks ---> video , files , parameters
  const getDataOfLinks = (link, setData) => {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;
      fetch(link, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data);
        });
    }
  };
  const [videoLink, setVideoLink] = useState("");
  const [paremeterLink, setparemeterLink] = useState("");

  useEffect(() => {
    if (links) {
      const videoLinkFetch = links[0].href;
      const exerciseFilesLinkFetch = links[1].href;
      const exerciseParametersLinkFetch = links[2].href;

      setVideoLink(videoLinkFetch);
      setparemeterLink(exerciseParametersLinkFetch);

      // Get Video Data
      getDataOfLinks(videoLinkFetch, setVideos);

      // // Get Exercise Files Data
      getDataOfLinks(exerciseFilesLinkFetch, setExerciseFiles);

      // // Get Video Data
      // getDataOfLinks(exerciseParametersLinkFetch, setExerciseParameters)
    }
  }, [links]);
  // **____________________________________________________________

  useEffect(() => {
    if (exerciseFiles.length > 0) {
      setDataReceived(true);
    }
  }, [exerciseFiles]);

  useEffect(() => {
    if (videos.length > 0) {
      setDataReceived(true);
    }
  }, [videos]);

  //   ===========================
  const [educationsFiles, setEducationsFiles] = useState([]);
  const [exerciseFiles0, setExerciseFiles0] = useState([]);

  const clickToExercise = (id) => {
    setEducationsFiles([]);
    setExerciseFiles0([]);
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      // setIsLodingGetData(true)
      fetch(`${HOST_NAME}/api/v1/Exercise/${id}/ExerciseFiles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // setexercisePatientPackages(data);
          setModalOpen(true);
          setExerciseFiles0(data);

          // setIsLodingGetData(false)
        })
        .catch((e) => {
          // setIsLodingGetData(true)
          console.log(e);
        });
    }
  };

  return (
    <div className="caption-card row">
      <div className="section-name-caption-cardExersice">{name}</div>

      {/* section 3 Form information */}
      <Parameters
        setParameters={setParameters}
        link={paremeterLink}
        keyID={keyID}
      />

      {/* section 4 Detail*/}
      <div className="section-detail-caption-cardExersice">
        <ul>
          <li style={{ fontSize: "1.5rem", color: "#1976d2" }}> {subject1}</li>
          <ul>
            <li style={{ margin: "10px", listStyle: "inside" }}>
              {" "}
              {preparation}
            </li>
          </ul>
          <li style={{ fontSize: "1.5rem", color: "#1976d2" }}> {subject2}</li>
          <ul>
            <li style={{ margin: "10px", listStyle: "inside" }}>
              {" "}
              {execution}
            </li>
          </ul>
        </ul>
      </div>

      {/* section 5 video and images*/}
      <div className="container-fluid section-video-image-caption-cardExersice">
        <div>
          <Button
            className="show-video-btn"
            onClick={() => clickToExercise(keyID)}
          >
            نمایش تصاویر و ویدیو ها
          </Button>
          <VideoModal
            isOpen={modalOpen}
            handleClose={handleCloseModal}
            media={exerciseFiles0}
          />
        </div>
      </div>
    </div>
  );
}
