import React, { createContext, useState } from 'react';

export const SelectionEducationIDContext = createContext();

const SelectionEducationIDProvider = ({ children }) => {
    const [selectedItemIDs, setSelectedItemIDs] = useState([]);

  return (
    <SelectionEducationIDContext.Provider value={{ selectedItemIDs, setSelectedItemIDs }}>
      {children}
    </SelectionEducationIDContext.Provider>
  );
};

export { SelectionEducationIDProvider };