import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function Loader({ noText }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        margin: noText ? 0 : "2.5rem",
      }}
    >
      <CircularProgress size={noText ? 20 : 40} />
      {!noText && (
        <div>
          <p
            style={{
              fontFamily: "IRANSans",
              margin: "1.5rem",
              fontSize: "1.2rem",
            }}
          >
            لطفا منتظر بمانید ...
          </p>
        </div>
      )}
    </Box>
  );
}
