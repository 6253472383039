import { Container, InputAdornment, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";

import "./SearchBar.css";
import MuiRTL from "../MuiRTL/MuiRTL";

export default function SearchBar({ handleSearch }) {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);

  return (
    <Container className="search-container" maxWidth="md" sx={{ mt: 0 }}>
      <MuiRTL>
        <TextField
          id="search"
          type="search"
          label="جستجو"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: "50rem" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </MuiRTL>
    </Container>
  );
}
