import React, { useState, useEffect, useContext } from "react";
import "./SingleTemplate.css";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { CardContext } from "../../Contexts/CardContext";
import CardExercise from "../CardExercise/CardExersice";
import ListItemEducationIndex from "../LeftSide/ListItemsEducationIndex/ListItemEducationIndex";
import { PackagesMadeContext } from "../../Contexts/PackagesMade";
import { posetEducationForPackage, postCardForPackage } from "../../utils";
import { PackageID } from "../../Contexts/PackageID";
import { EducationSelectedInModalInexPage } from "../../Contexts/EducationSelectedInModalInexPageContext";
import { Edit, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import DisplayImages from "../DisplayImages/DisplayImages";
import useMakePackage from "../../Hooks/useMakePackage";
import { HOST_NAME } from "../../config";

export default function SingleTemplate({
  type,
  keyID,
  templateID,
  packageName,
  packageDescription,
  exercises,
  educations,
  setTemplates,
  isValidateToDelete,
}) {
  const {
    isTabPanelOpen,
    setIsTabPanelOpen,
    setIsEditingTabPanel,
    setCoverContent,
    setExerciseParameters,
    coverContent,
  } = useContext(PackagesMadeContext);

  const { updateselectedAddCardVariable } = useContext(CardContext);
  const { getPackageNme } = useMakePackage();
  const { setEducationSelected } = useContext(EducationSelectedInModalInexPage);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({});
  const navigate = useNavigate();
  const { packageID, setTemplateID } = useContext(PackageID);

  useEffect(() => {
    isModalOpen && getTemplateDetails();
  }, [isModalOpen]);

  function getTemplateDetails() {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;
      fetch(`${HOST_NAME}/api/v1/Template/${keyID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("template details", data);
          // setTemplateDetails(data);
          console.log("template id", keyID);

          fetch(
            `${HOST_NAME}/api/v1/Template/GetDeviders?templateId=${keyID}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((res) => {
              console.log("get deviders res", res);
              return res.json();
            })
            .then((deviders) => {
              const sortedDeviders = deviders.sort(
                (a, b) => a.rowIndex - b.rowIndex
              );
              sortedDeviders.map((devider) => [
                ...data.exercises.splice(devider.rowIndex, 0, devider),
              ]);

              console.log("package deviders & exercises", sortedDeviders, data);
              setTemplateDetails(data);
            })
            .catch((e) => console.log(e));
        });
    }
  }

  function closeModalDetails() {
    setIsModalOpen(false);
  }

  function addPackageToSelected(isPanelOpen, cards, editMode) {
    if (isPanelOpen) {
      setCoverContent({
        contentTitle: packageName,
        contentDescription: packageDescription,
      });
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        const token = parsedData.token;

        cards.exercises.forEach((exercise) => {
          fetch(
            `${HOST_NAME}/api/v1/Template/${cards.keyID}/Exercises/${exercise.keyID}/Parameters`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((params) =>
              setExerciseParameters((prevState) => [
                ...prevState,
                { exerciseID: exercise.keyID, params },
              ])
            );
        });
      }
      if (editMode) {
        // getPackageNme(
        //   {
        //     contentTitle: templateDetails.packageName,
        //     contentDescription: templateDetails.packageDescription,
        //   },
        //   (newPackageID) => {
        //     cards.exercises.forEach((exercise) =>
        //       postCardForPackage(exercise.keyID, newPackageID)
        //     );

        //     cards.educations.forEach((education) =>
        //       posetEducationForPackage(education.keyID, newPackageID)
        //     );
        //   }
        // );

        updateselectedAddCardVariable([...cards.exercises]);
        console.log("testtttttttttttt template", { packageID: cards.keyID });

        setEducationSelected([...cards.educations]);
      } else {
        cards.exercises.map((exercise) => {
          updateselectedAddCardVariable((prev) => {
            const isExerciseAlreadyAdded = prev.some(
              (item) => item.keyID === exercise.keyID
            );

            if (!isExerciseAlreadyAdded) {
              // postCardForPackage(exercise.keyID, packageID);
              return [...prev, exercise];
            }

            return prev;
          });
        });

        cards.educations.map((education) => {
          setEducationSelected((prev) => {
            const isEducationAlreadyAdded = prev.some(
              (item) => item.keyID === education.keyID
            );

            if (!isEducationAlreadyAdded) {
              // posetEducationForPackage(education.keyID, packageID);
              return [...prev, education];
            }
            return prev;
          });
        });
      }

      setTemplateID(editMode ? keyID : -1);
    } else {
      setIsTabPanelOpen(true);
      setIsEditingTabPanel(editMode);
      addPackageToSelected(true, cards, editMode);
    }
    navigate("/build");
  }

  function deleteTemplate() {
    closeModalDetails();
    Swal.fire({
      title: `آیا مطمئن هستید که الگو حذف شود؟`,
      icon: "warning",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        const localStorageData = localStorage.getItem("user");
        if (localStorageData) {
          const parsedData = JSON.parse(localStorageData);
          const token = parsedData.token;
          fetch(`${HOST_NAME}/api/v1/Template/${keyID}`, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then((res) =>
            setTemplates((templates) =>
              templates.filter((template) => template.keyID !== keyID)
            )
          );
        }
      }
    });
  }

  const limit = 5;

  const limitedExecises = exercises.slice(0, limit);
  const limitedEducations = educations.slice(0, limit - limitedExecises.length);
  const bothLimited = [...limitedExecises, ...limitedEducations];

  return (
    <>
      <div onClick={() => setIsModalOpen(true)} className="template-wrapper">
        <div className="images-container">
          {bothLimited.map((card) => (
            <DisplayImages key={card.keyID} item={card} />
          ))}

          {exercises.length + educations.length > bothLimited.length && (
            <span>...</span>
          )}
        </div>
        <div className="single-template-content">
          <span className="template-title">{packageName}</span>
          <div>
            <span className="exercise-count">{exercises.length} عدد تمرین</span>
            <span className="exercise-count">
              {educations.length} عدد آموزش
            </span>
          </div>
        </div>
      </div>

      {Object.keys(templateDetails).length > 0 && (
        <Dialog
          className="template-dialog"
          open={isModalOpen}
          onClose={closeModalDetails}
        >
          <div className="package-header">
            <span>{packageName}</span>
            <div className="single-template-options">
              {isValidateToDelete && (
                <>
                  <button onClick={deleteTemplate}>
                    <Delete />
                  </button>
                  <button
                    onClick={() =>
                      addPackageToSelected(
                        isTabPanelOpen,
                        templateDetails,
                        true
                      )
                    }
                  >
                    <Edit />
                  </button>
                </>
              )}

              <button
                onClick={() =>
                  addPackageToSelected(isTabPanelOpen, templateDetails)
                }
              >
                <AddShoppingCartIcon />
              </button>
            </div>
          </div>
          <p style={{ paddingInline: "20px" }}>{packageDescription}</p>
          <div className="package-exercises">
            {templateDetails.exercises.map((exercise) =>
              exercise.fK_TemplateID ? (
                <div key={exercise.keyID} className="single-template-devider">
                  <h3>{exercise.title}</h3>
                  <h4>{exercise.instruction}</h4>
                </div>
              ) : (
                <CardExercise
                  key={exercise.keyID}
                  {...exercise}
                  isShowTreemenu={false}
                  isForTemplate
                  templateID={templateID}
                />
              )
            )}

            {templateDetails.educations.map((education) => (
              <ListItemEducationIndex
                key={education.keyID}
                {...education}
                isForTemplate
              />
            ))}
          </div>
        </Dialog>
      )}
    </>
  );
}
