import React, { useContext } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { CardExersiceContext } from './../../Contexts/CardsExesciseContext'

import MuiRTL from '../MuiRTL/MuiRTL';

export default function Pagination_() {

  const { setCurrentPage, countPageNation ,currentPage } = useContext(CardExersiceContext)

  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  };

  return (
    <MuiRTL>
      <Stack spacing={2} direction='rtl' style = {{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
        <Pagination count={countPageNation} color="primary" page={currentPage} onChange={handlePageChange}/>
      </Stack>
    </MuiRTL>
  );
}