import React, { createContext, useState } from "react";

export const PackagesMadeContext = createContext();

const PackagesMadeProvider = ({ children }) => {
  const [packagesMade, setPackagesMade] = useState({
    exercises: [],
    educations: [],
  });

  const [exerciseParameters, setExerciseParameters] = useState([]);
  const [isTabPanelOpen, setIsTabPanelOpen] = useState(false);
  const [isEditingTabPanel, setIsEditingTabPanel] = useState(false);
  const [coverContent, setCoverContent] = useState({});

  return (
    <PackagesMadeContext.Provider
      value={{
        packagesMade,
        setPackagesMade,
        isTabPanelOpen,
        setIsTabPanelOpen,
        isEditingTabPanel,
        setIsEditingTabPanel,
        coverContent,
        setCoverContent,
        exerciseParameters,
        setExerciseParameters,
      }}
    >
      {children}
    </PackagesMadeContext.Provider>
  );
};

export { PackagesMadeProvider };
