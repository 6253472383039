import { useContext } from "react";
import { PackageID } from "../Contexts/PackageID";
import { HOST_NAME } from "../config";

export default function useMakePackage() {
  const { updatePackageIDVariable } = useContext(PackageID);

  const getPackageNme = ({ content }, handleFn) => {
    const localStorageData = localStorage.getItem("user");
    const newPackage = {
      keyID: 0,
      packageName: content?.contentTitle || "",
      packageDescription: content?.contentDescription || "",
    };
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/Package`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPackage),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok");
        })
        .then((result) => {
          handleFn && handleFn(result);
          updatePackageIDVariable(result);
        })

        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return { getPackageNme };
}
