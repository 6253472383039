import React from "react";
import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";

export default function About() {
  return (
    <>
      <ResponsiveNavbar />
      {/* <h1>درباره ما</h1> */}
      <h1>در دست ساخت</h1>
    </>
  );
}
