import React from "react";
import BasicTabs from "../Tab/Tab";

import "./RightSide.css";

export default function RightSide() {
  return (
    <div className="right-side__mainSection">
      <div className="container-fluid" style={{ paddingLeft: "0px" }}>
        <BasicTabs />
      </div>
    </div>
  );
}
