import React, { useState } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import "./ImageUploadBox.css";
export default function ImageUploadBox({
  onImageUpload,
  onRemoveImage,
  image,
  title,
}) {
  const [isDragging, setIsDragging] = useState(false);

  function handleDragOver(e) {
    e.preventDefault();
    setIsDragging(true);
  }
  function handleDragLeave() {
    setIsDragging(false);
  }
  function handleDrop(e) {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];

    if (file && file.type.startsWith("image/")) {
      onImageUpload(file);
    }
  }

  function handleFileChange(e) {
    const file = e.target.files[0];

    if (file && file.type.startsWith("image/")) {
      onImageUpload(file);
    }
  }

  return (
    <div
      className={`upload-box ${isDragging ? "dragging" : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {image ? (
        <div className="image-container">
          <img src={image} alt={title} className="image-preview" />
          <button className="remove-btn" onClick={onRemoveImage}>
            <DeleteOutlineOutlinedIcon />
          </button>
        </div>
      ) : (
        <div className="file-input-container">
          <span>
            <CloudUploadOutlinedIcon className="upload-icon" /> کلیک کنید یا
            فایل <span className="image-type">{title}</span> خود را اینجا بکشید و رها کنید
          </span>
          <input type="file" onChange={handleFileChange} accept="image/*" />
        </div>
      )}
    </div>
  );
}
