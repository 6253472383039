import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import CircularProgress from '@mui/material/CircularProgress';
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';
const SettingsButton = ({ icon }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);

    // Simulate an asynchronous operation (e.g., fetching settings)
    setTimeout(() => {
      setIsLoading(false);
    }, 5000); // 5 seconds

    // You can also add any other logic you need after the button is clicked
  };

  const override = css`
  display: block;
  margin: 0 auto;
`;

  return (
    <div>
      <IconButton
        color="red"
        aria-label="settings"
        onClick={handleClick}
        disabled={isLoading}
      >
        {isLoading ? (
          <RingLoader color={'black'} loading={true}  size={20} />
        ) : (
            icon
        )}
      </IconButton>
    </div>
  );
};

export default SettingsButton;
