import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const VideoModal = ({ isOpen, handleClose, media }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      scroll="paper"
      fullWidth
      maxWidth="md"
    >
      <DialogContent dividers>
        <Stack spacing={2}>
          {media.map((item, index) => (
            <div key={item.keyID} style={{ margin: "2rem" }}>
              {item.isImage ? (
                <div style={{ padding: "2rem", border: "1px dashed" }}>
                  <img
                    src={item.filesAddress || item.fullpath}
                    alt={item.fileDescription}
                    style={{ width: "100%", height: "auto" }}
                  />
                  {item.fileTitle}
                </div>
              ) : (
                <video width="100%" controls>
                  {item.videoTitle}
                  <source src={item.filesAddress || item.fullpath} type="video/mp4" />
                  {item.videoDescription}
                  مرورگر شما ویدئو را پشتیبانی نمی‌کند.
                </video>
              )}
            </div>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          بستن
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoModal;
