import React, { useState, useEffect, useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import "./ChatBox.css";
import { formatDate, formatTime } from "../../utils";
import Loader from "../Loader/Loader";
import { HOST_NAME } from "../../config";

export default function ChatBox({
  activeChat,
  starterUser,
  opponent,
  isModal,
}) {
  const [chatDetails, setChatDetails] = useState();
  const [newMessage, setNewMessage] = useState("");
  const allChatsRef = useRef();

  useEffect(() => {
    if (chatDetails) {
      allChatsRef.current.scrollTop = allChatsRef.current.scrollHeight;
    }
  }, [chatDetails]);

  useEffect(() => {
    activeChat && getTicketDetails();
  }, [activeChat]);

  function getTicketDetails() {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/Ticket/${activeChat}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setChatDetails(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  function sendMessage(e) {
    e.preventDefault();

    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      const newMessageObj = {
        keyID: 0,
        content: newMessage,
      };

      fetch(
        `${HOST_NAME}/api/v1/Ticket/${chatDetails.keyID}/Messsages`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newMessageObj),
        }
      )
        .then(() => {
          setNewMessage("");
          getTicketDetails();
        })
        .catch((error) => console.log("send meessage error", error));
    }
  }

  return (
    <div className="chat-container">
      {chatDetails ? (
        <div className="chat-wrapper">
          <div>
            <div className="chat-title">
              <span>{chatDetails.subject}</span>
              <hr />
            </div>
            <div ref={allChatsRef} className="all-chats">
              {starterUser.fullname && opponent.fullname ? (
                chatDetails.messages.map((message) => (
                  <div
                    key={message.keyID}
                    className={`chat ${isModal ? "modal" : ""} ${
                      message.senderID === starterUser.userID
                        ? "sent"
                        : "received"
                    }`}
                  >
                    <div className="chat-header">
                      <span className="message-owner">
                        {message.senderID === starterUser.userID
                          ? starterUser.fullname
                          : opponent.fullname}
                      </span>
                      <div className="chat-date-time">
                        <span>{formatTime(message.createDate)} | </span>
                        <span>{formatDate(message.createDate)}</span>
                      </div>
                    </div>
                    <p>{message.content}</p>
                  </div>
                ))
              ) : (
                <Loader />
              )}
            </div>
          </div>
          <form>
            <button onClick={sendMessage}>
              <SendIcon />
            </button>
            <input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              type="text"
              placeholder="پیام خود را بنویسید"
            />
          </form>
        </div>
      ) : null}
    </div>
  );
}
